import React, { useEffect, useState } from "react";
import { Form, Input, message } from "antd";
import ContactPerson from "./ContactPerson";
import _ from "lodash";
import useGeneric from "../../../../../utils/hooks/user/useGeneric";
import useCountriesStates from "../../../../../utils/hooks/auth/useCountriesStates";
import useGroupServices from "../../../../../utils/hooks/user/useGroupServices";
import InlineError from "../../../../misc/InlineError";
import AgroButton from "../../../../misc/AgroButton";
// import axios from "axios";
// import qs from 'qs';

const success = "Successful";
const error = "An error has occured";


const EditCooperative = ({ completed, cooperative }) => {
  const [focusItems, setFocusItems] = useState(cooperative?.cooperative_focus_items);

  const [focus, setFocus] = useState({});
  const [location, setLocation] = useState({});
  const [contactPersons, setContactPersons] = useState(cooperative?.contacts);
  const [allCategories, setAllCategories] = useState([]);
  // const [allSubCategories, setAllSubCategories] = useState([]);
  // const [allItems, setAllItems] = useState([]);
  
  const [country, setCountry] = useState({});
  const [state, setState] = useState({});
  const [city, setCity] = useState({});

  const selectFewerContactsProps = (generateContacts)=>{
    const {title, first_name, surname, email, role, phone} = generateContacts;
    return {title, first_name, surname, email, role, phone};
  }
  const newContacts = contactPersons.map(selectFewerContactsProps);

  const selectFewerFocusItemsProps = (generateFocusItems)=>{
    const {item_id} = generateFocusItems;
    return {item_id};
  }
  const newFocusitems = focusItems.map(selectFewerFocusItemsProps);
  
  const [formData, setFormData] = useState({
    name:cooperative?.name,
    website:cooperative?.website,
    phone:cooperative?.phone,
    email:cooperative?.email,
    country:cooperative?.country_id,
    state:cooperative?.state_id,
    city:cooperative?.city_id,
    address:cooperative?.address,
    items:newFocusitems,
    contacts:newContacts
  });

  
  const {
    categoriesData,
    getCategories,
    // getSubcategories,
    subcategoriesListData,
    // setSubcategoriesListData,
    getSubcategoriesList,
    getSubcategoriesForACategory,
    itemsListData,
    // setItemsListData,
    getItemsList,
  } = useGeneric();
  
  const {
    countriesData,
    getCountries,
    statesData,
    setStatesData,
    getStates,
    citiesData,
    setCitiesData,
    getCities,
  } = useCountriesStates();
  const {
    // updateCooperativeLoading,
    updateCooperativeFailure,
    setUpdateCooperativeFailure,
    updateCooperative,
  } = useGroupServices();

  const titles = ["Mr", "Mrs", "Miss", "Ms", "Dr"];
  const roles = ["Chairman", "Secretary", "Treasurer", "Officer"];

  const updateFormData = (event) => {
    const name = event.target.getAttribute("name");

    const value = event.target.value;

    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
    console.log(formData);
  }

  const arrangeCategories = ()=>{
    const cats = allCategories;
    const subCats = [];
    const items = [];
    focusItems?.forEach(function(currentValue, index){
      cats[index] = currentValue?.item?.item_category;
      subCats[index] = getSubcategoriesList(cats[index].id, index);
      items[index] = getItemsList(currentValue?.item?.item_sub_category_id, index);
      
    });
    
    
    
  }

  const handleCategoryChange = async (e) => {
    const id = await e.target.value;
    const index = await e.target.getAttribute('index');
    getSubcategoriesForACategory(id, index);
    console.log(subcategoriesListData)
  };

  const handleSubCategoryChange = async (e) => {
    const id = await e.target.value;
    const index = await e.target.getAttribute('index');
    // setItems({ ...Items, sub_category: id });
    getItemsList(id, index, true);
  };

  const handleItemChange = async (e) => {
    const id = e.target.value;
    const index = await e.target.getAttribute('index');
    const newItems = formData.items;
    newItems[index] ={item_id:id};
    const newFormData = { ...formData, items: newItems };
    setFormData(newFormData);
  };

  const handleContactChange = async (e) => {
    const value = e.target.value;
    const index = await e.target.getAttribute('index');
    const name = await e.target.getAttribute('name');
    const newContacts = formData.contacts;
    newContacts[index][name] =value;
    const newFormData = { ...formData, contacts: newContacts };
    setFormData(newFormData);
    console.log(formData)
  };

  const handleCountryChange = (e) => {

    const id = e.target.value;
    setLocation({ ...location, country: id });
    setStatesData([]);
    setCitiesData([]);
    getStates(id);

    const newFormData = { ...formData, country: id };
    setFormData(newFormData);
    console.log(formData)
  };

  const handleStateChange = (e) => {
    const id = e.target.value;
    setLocation({ ...location, state: id });
    setCitiesData([]);
    getCities(id);

    const newFormData = { ...formData, state: id };
    setFormData(newFormData);
    console.log(formData)
  };

  const handleCityChange = (e) => {
    const id = e.target.value;
    setLocation({
      ...location,
      city: id,
    });
    const newFormData = { ...formData, city: id };
    setFormData(newFormData);
  };

  const filterDefaultSubCategory = (givenId, index)=>{
    const doFilter =  subcategoriesListData[index]?.filter(function(singleItem){
      return singleItem.id == givenId
    })
    return doFilter?.length;
  }


  const filterDefaultItem = (givenId, index)=>{
    const doFilter = itemsListData[index]?.filter(function(singleItem){
      return singleItem.id == givenId
    })
    return doFilter?.length;
  }

  const filterDefaultState = (givenId)=>{
    const doFilter = statesData?.filter(function(singleItem){
      return singleItem.id == givenId
    })
    return doFilter?.length;
  }

  const filterDefaultCity = (givenId)=>{
    const doFilter = citiesData?.filter(function(singleItem){
      return singleItem.id == givenId
    })
    return doFilter?.length;
  }


  useEffect(() => {
    getCountries();
   
  }, []);

  useEffect(() => {
    getStates(cooperative?.country_id);
  }, []);

  useEffect(() => {
    getCities(cooperative?.state_id);
  }, []);

  useEffect(() => {
    const country_ = countriesData.find(
      (c) => c.id === cooperative?.country_id
    );
    setCountry({
      name:country_?.name,
      id:country_?.id,
    });
    
  }, [countriesData]);

  useEffect(() => {
    const state_ = statesData.find((s) => s.id === cooperative?.state_id);
    setState({
      name:state_?.name,
      id:state_?.id,
    });
  }, [statesData]);

  useEffect(() => {
    const city_ = citiesData.find((c) => c.id === cooperative?.city_id);
    setCity({
      name:city_?.name,
      id:city_?.id,
    });
  }, [citiesData]);

  useEffect(()=>{
    arrangeCategories();
  }, []);

  

  useEffect(() => {
    getCountries();
    getCategories();
     const forms = document.querySelectorAll('.needs-validation')

      // Loop over them and prevent submission
      Array.from(forms).forEach(form => {
        form.addEventListener('submit', event => {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          form.classList.add('was-validated')
        }, false)
      })
  }, []);

  const createNewFocusItemElement = ()=>{
    const updatedFocusItems = [...focusItems]
   
    updatedFocusItems.push(
      {
        
        item: {
            name: "",
            item_category_id: "",
            item_sub_category_id: "",
           
            item_category: {
                id: "",
                name: ""
            },
            item_sub_category: {
                id: "",
                name: "",
                item_category_id: ""
            }
        }
    }
    );

    setFocusItems(updatedFocusItems);
   
  }

  const removeFocusItem = (event)=>{
    const updatedFocusItems = [...focusItems];
    updatedFocusItems.splice(event.target.getAttribute('index'), 1);
     setFocusItems(updatedFocusItems);
  }

  const handleKeyPress =(ev)=>{
    const charLen = ev.target.value.length;
    if(charLen >=11){
      ev.preventDefault();
    }
    
  }

  const handleSubmit = (event) => {
     event.preventDefault();
    const formData = new FormData();
    // formData.append("name")
    updateCooperative(formData, cooperative?.id).then((data) => {
      if (data?.success) {
        message.success(data?.message || success);
        completed();
      } else {
        message.error(data?.message || error);
      }
    });
  };

  

  return (
    <>
      <InlineError
        control={updateCooperativeFailure}
        message={updateCooperativeFailure}
        onClose={() => setUpdateCooperativeFailure(null)}
      />
      <form onSubmit={handleSubmit}>
          <div className="row mt-3">
            <div className="col-md-4 mt-3 mt-md-0">
              <div className="form-group">
                <label className="text-xs">Name</label>
                <input name="name" type="text" required className="form-control ant-input" placeholder="Name of cooperative" defaultValue={cooperative?.name} onInput={updateFormData} />

              </div>
            </div>

            <div className="col-md-4 mt-3 mt-md-0">
              <div className="form-group">
                <label className="text-xs">Email</label>
                <input name="email" type="email" required className="form-control ant-input" placeholder="cooperative email address"  defaultValue={cooperative?.email} onInput={updateFormData} />

              </div>
            </div>

            <div className="col-md-4 mt-3 mt-md-0">
              <div className="form-group">
                <label className="text-xs">Phone</label>
                <input name="phone" type="number" required className="form-control ant-input webkit-disabled" placeholder="Name of cooperative"  maxLength={11} defaultValue={cooperative?.phone} onInput={updateFormData} onKeyPress={handleKeyPress}/>

              </div>
            </div>

          </div>

          <div className="row mt-md-3">
            <div className="col-md-4 mt-3 mt-md-0">
              <div className="form-group">
                <label className="text-xs">Country</label>
                <select name="country" required className="form-control ant-input" onChange={handleCountryChange}>\
                  <option value={country.id}>{country.name}</option>
                  {countriesData?.map((each_country, index)=>(
                    <option key={each_country.name} value={each_country.id}>{each_country.name}</option>

                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-4 mt-3 mt-md-0">
              <div className="form-group">
                <label className="text-xs">State</label>
                <select name="state" required className="form-control ant-input" onChange={handleStateChange}>
                  {
                    filterDefaultState(state.id) > 0 ? 
                      <option value={state.id} >{state?.name}</option>
                    :
                    <></>
                    
                  }
                  {statesData?.map((each_state, index)=>(
                    <option key={each_state.name} value={each_state.id}>{each_state.name}</option>

                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-4 mt-3 mt-md-0">
              <div className="form-group">
                <label className="text-xs">City</label>
                <select name="city" required className="form-control ant-input" onChange={handleCityChange}>
                {
                    filterDefaultCity(city.id) > 0 ? 
                      <option value={city.id}>{city.name}</option>
                    :
                    <></>
                    
                  }
                  {citiesData?.map((each_city, index)=>(
                    <option key={each_city.name} value={each_city.name}>{each_city.name}</option>

                  ))}
                </select>
              </div>
            </div>

          </div>

          <div className="row mt-3">
            <div className="col-12 mt-3 mt-md-0">
              <div className="form-group">
                <label className="text-xs">Address</label>
                <textarea name="address" required className="form-control ant-input" placeholder="Address" defaultValue={cooperative?.address}></textarea>

              </div>
            </div>

            <div className="col-12 mt-3">
              <label className="text-xs">Website</label>
              <div className="input-group">
                <span className="input-group-text" id="website">{cooperative?.website?.substring(0, 8)}</span>
                <input type="text" className="form-control ant-input" id="basic-url" aria-describedby="website" defaultValue={cooperative?.website?.substring(8)}/>
              </div>
            </div>

          </div>

          <div className="row mt-5">
            <div className="col-12 mb-2">

              <span>Focus Item(s)</span>
            </div>
            {focusItems?.map((item, index)=>(
              <div className="row mt-3" key={index}>

                <div className="col-md-4 mt-3 mt-md-0">
                  <div className="form-group">
                    <label className="text-xs">Category</label>
                    <select name="category" required className="form-control ant-input" defaultValue="production" onChange={handleCategoryChange} index={index}>
                      <option value={allCategories[index]?.id} >{allCategories[index]?.name}</option>
                      {categoriesData.map((category, catIndex)=>(
                        <option key={category.id} value={category?.id}>{category?.name}</option>
                      ))}
                      
                    </select>
                  </div>
                </div>

                <div className="col-md-4 mt-3 mt-md-0">
                  <div className="form-group">
                    <label className="text-xs">Sub Category</label>
                    <select name="sub_category" required className="form-control ant-input" onChange={handleSubCategoryChange} index={index}>
                      {
                        filterDefaultSubCategory(item?.item?.item_sub_category?.id, index) > 0 ? 
                         <option value={item?.item?.item_sub_category?.id} >{item?.item?.item_sub_category?.name}</option>
                        :
                        <></>
                        
                      }
                     
                      {subcategoriesListData[index]?.map((sub_category, subCatIndex)=>(
                        <option key={subCatIndex} value={sub_category?.id}>{sub_category?.name}</option>
                      ))}
                      
                    </select>
                  </div>
                </div>

                <div className="col-md-4 mt-3 mt-md-0">
                  <div className="form-group">
                    <label className="text-xs">Item</label>
                    <select name="item" required className="form-control ant-input" onChange={handleItemChange} index={index}>
                      {
                        filterDefaultItem(item?.item?.item_sub_category?.id, index) > 0 ? 
                        <option value={item?.item?.id} >{item?.item?.name}</option>
                        :
                        <></>
                        
                      }
                      
                      {itemsListData[index]?.map((singleItem, itemIndex)=>(
                        <option key={itemIndex} value={singleItem?.id} >{singleItem?.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12 text-end py-2">
                  <button className="btn btn-sm bg-danger text-white" type="button" onClick={removeFocusItem} index={index}> <i className="bi bi-trash"></i></button>
                </div>
              </div>
            ))}

            <div className="col-12 text-end py-2">
              <button className="btn-primary p-2" type="button" onClick={createNewFocusItemElement}>Add New Focus Item</button>
            </div>

          </div>

          <div className="row mt-5">
            <div className="col-12 mb-3">
          
              <span>Contact Person(s)</span>
            </div>
            {cooperative?.contacts?.map((item, index)=>(
              <div className="row mt-4" key={item.id}>
               
                <div className="col-md-4 mt-3 mt-md-0">
                  <div className="form-group">
                    <label className="text-xs">Title</label>
                    <select name="title" index={index} onInput={handleContactChange} required className="form-control ant-input" defaultValue="Mr">

                      <option value={item?.title}>{item?.title}</option>
                      {titles.map((title)=>(
                        <option key={title} value={title}>{title}</option>
                      ))}
                      
                    </select>
                  </div>
                </div>

                <div className="col-md-4 mt-3 mt-md-0">
                  <div className="form-group">
                    <label className="text-xs">First Name</label>
                    <input name="first_name" index={index} onInput={handleContactChange} type="text" required className="form-control ant-input" placeholder="First Name of contact person" defaultValue={item?.first_name} />
                  </div>
                </div>

                <div className="col-md-4 mt-3 mt-md-0">
                  <div className="form-group">
                    <label className="text-xs">Surname</label>
                    <input name="surname" index={index} onInput={handleContactChange} type="text" required className="form-control ant-input" placeholder="Last Name of contact person" defaultValue={item?.surname}/>
                    
                  </div>
                </div>


                <div className="col-md-4 mt-3 mt-md-0">
                  <div className="form-group">
                    <label className="text-xs">Role</label>
                    <select name="role" index={index} onInput={handleContactChange} required className="form-control ant-input" defaultValue={"Chairman"}>
                      <option value={item?.role}>{item?.role}</option>
                      {roles.map((role, roleIndex)=>(
                         <option key={roleIndex} value={role}>{role}</option>
                      ))}
                     
                    </select>
                  </div>
                </div>

                <div className="col-md-4 mt-3 mt-md-0">
                  <div className="form-group">
                    <label className="text-xs">Email</label>
                    <input name="contact_email" type="email" required className="form-control ant-input" placeholder="Email of contact person" defaultValue={item?.email} />
                  </div>
                </div>

                <div className="col-md-4 mt-3 mt-md-0">
                  <div className="form-group">
                    <label className="text-xs">Phone</label>
                    <input name="contact_phone" index={index} onInput={handleContactChange} type="phone" required className="form-control ant-input webkit-disabled" placeholder="Phone number of contact person" defaultValue={item?.phone} maxLength={'11'} minLenght={'11'} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center p-3">
            <button type="submit" className="transition-all hover:bg-[#28812c] tracking-wider rounded-[2px]  text-white py-[6px] px-3 border border-gray-400 bg-[#3dd800] px-10">Update cooperative</button>
          </div>
         
      </form>
    </>
  );
};

export default EditCooperative;
