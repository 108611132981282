import React from "react";
import GenericLayout from "../../containers/layouts/GenericLayout";

function Services() {
	return (
		<GenericLayout>
		<section 
			id="header" 
			className="hero-header" 
			style={{backgroundImage: "url(/img/service_hero.jpg)" }}>

			<div className="container">
				<div className="wow fadeInDown hero-wrap text-center" data-wow-delay=".2s">
					<div className="page-title text-white">
						<h2 className="title text-white">Services</h2>
						<p className="description">The platform offers various benefits including but not limited to technical assistance, access to
							loans and credit, indexed directory services, rich agricultural blog, micro-ecommerce website,
							marketplace, aggregation, cooperative services, evacuation services, records management, crowdfunding etc.</p>
					</div>
					<div className="page-breadcrumbs">
						<div className="breadcrumbs text-center">
							<ul>
								<li className="base"><a href="#">Home</a></li>
								<li className="active"><a href="#">Services</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>

		</section>
		<section
      		id="section-1"
      		className="page-content pt-20 pb-20 service-section"
    	>
      	<div className="container">
        	<div className="wow fadeInUp bg-white" data-wow-delay=".2s">
          		<div className="-mx-4 flex flex-wrap row">
					<div className="col-md-6">
						<div className="service-item">
							<div className="section-title-wrap">
								<span className="mb-5 section-subtitle">Service</span>
								<h2 className="mb-6 section-title font-bold">
									Farmer Data<br /> Management
								</h2>
							</div>

							<div className="w-full">
								<div className="items-center justify-between overflow-hidden lg:flex">
									<div className="w-full">
										<p className="mb-4 text-base leading-relaxed text-body-color">
										The platform comes with a robust database management system that allows users
										capture farmers within their domain. The database management system comes with
										capability to perform reporting, analytics and messaging via the platform and also
										through external channels like email, short message service (SMS) and whatsapp.
										</p>
									</div>
								</div>
							</div>
						</div>			
					</div>

					<div className="col-md-6">
						<div className="service-item">
							<div className="section-title-wrap">
								<span className="mb-5 section-subtitle">Service</span>
								<h2 className="mb-6 section-title font-bold">
									Aggregation<br /> and Distribution
								</h2>
							</div>

							<div className="w-full">
								<div className="items-center justify-between overflow-hidden lg:flex">
									<div className="w-full">
										<p className="mb-4 text-base leading-relaxed text-body-color">
											Our aggregation and distribution features provides robust services along the value chain
											including but not limited to product aggregation, offtake management, anchor borrower
											programme management. It also manages input supplies, evacuation, storage and ware
											housing, processing, transportation etc.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>				
			</div>
		</div>
    </section>

	<section
      	id="section-2"
      	className="page-content pt-20 pb-20 bg-grey service-section"
    >
      	<div className="container">
        	<div className="wow fadeInUp" data-wow-delay=".2s">
          		<div className="-mx-4 flex flex-wrap row">
					<div className="col-md-6">
						<div className="service-item">
							<div className="section-title-wrap">
								<span className="mb-5 section-subtitle">Service</span>
								<h2 className="mb-6 section-title font-bold">
									Associations and <br /> Cooperatives
								</h2>
							</div>

							<div className="w-full">
								<div className="items-center justify-between overflow-hidden lg:flex">
									<div className="w-full">
										<p className="mb-4 text-base leading-relaxed text-body-color">
										Our trading features gives users a 360 degree view of their trading providing the, with
										lead generation, negotiation, transaction, transportation and delivery. At any point of the
										trade the user can monitor the trade digitally. Users can also trade safely using our
										physical inspection services, secure payment and escrow account.
										</p>
									</div>
								</div>
							</div>	
						</div>		
					</div>

					<div className="col-md-6">
						<div className="service-item">
							<div className="section-title-wrap">
								<span className="mb-5 section-subtitle">Service</span>
								<h2 className="mb-6 section-title font-bold">
									Records <br />Management
								</h2>
							</div>

							<div className="w-full">
								<div className="items-center justify-between overflow-hidden lg:flex">
									<div className="w-full">
										<p className="mb-4 text-base leading-relaxed text-body-color">
										The platform offers a robust record management system to farmers that enables them
										keep farm records in a simple manner. It manages their income and expenditure,
										customer records, supplier records, inventory, products etc.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>				
			</div>
		</div>
    </section>

	<section
      	id="section-2"
      	className="page-content pt-20 pb-20 service-section"
    >
      	<div className="container">
        	<div className="wow fadeInUp bg-white" data-wow-delay=".2s">
          		<div className="-mx-4 flex flex-wrap row">
					<div className="col-md-6">
						<div className="service-item">
							<div className="section-title-wrap">
								<span className="mb-5 section-subtitle">Service</span>
								<h2 className="mb-6 section-title font-bold">
									Loans and Grants
								</h2>
							</div>

							<div className="w-full">
								<div className="items-center justify-between overflow-hidden lg:flex">
									<div className="w-full">
										<p className="mb-4 text-base leading-relaxed text-body-color">
										This gives users access to loans and grants. The feature automatically prequalifies the
										users thereby reducing the time required for loan processing and reduces processing
										time for loans.
										</p>
									</div>
								</div>
							</div>
						</div>			
					</div>

					<div className="col-md-6">
						<div className="service-item">
							<div className="section-title-wrap">
								<span className="mb-5 section-subtitle">Service</span>
								<h2 className="mb-6 section-title font-bold">
									Insurance
								</h2>
							</div>

							<div className="w-full">
								<div className="items-center justify-between overflow-hidden lg:flex">
									<div className="w-full">
										<p className="mb-4 text-base leading-relaxed text-body-color">
										This gives users access to various agricultural insurance types and providers.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>				
			</div>
		</div>
    </section>

	<section
      	id="section-2"
      	className="page-content pt-20 pb-20 bg-grey service-section"
    >
      	<div className="container">
        	<div className="wow fadeInUp" data-wow-delay=".2s">
          		<div className="-mx-4 flex flex-wrap row">
					<div className="col-md-6">
						<div className="service-item">
							<div className="section-title-wrap">
								<span className="mb-5 section-subtitle">Service</span>
								<h2 className="mb-6 section-title font-bold">
									Technical Assistance
								</h2>
							</div>

							<div className="w-full">
								<div className="items-center justify-between overflow-hidden lg:flex">
									<div className="w-full">
										<p className="mb-4 text-base leading-relaxed text-body-color">
										The platform offers various forms of technical assistance both digitally and in person to
										farmers on its database to improve their offerings such as crop yield, new markets,
										financing options etc
										</p>
									</div>
								</div>
							</div>
						</div>			
					</div>

					<div className="col-md-6">
						<div className="service-item">
							<div className="section-title-wrap">
								<span className="mb-5 section-subtitle">Service</span>
								<h2 className="mb-6 section-title font-bold">
									Governments
								</h2>
							</div>

							<div className="w-full">
								<div className="items-center justify-between overflow-hidden lg:flex">
									<div className="w-full">
										<p className="mb-4 text-base leading-relaxed text-body-color">
										The platforms provides unique set of features that enables governments better manage the agricultural programmes.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>				
			</div>
		</div>
    </section>

	<section
      	id="section-2"
      	className="page-content pt-20 pb-20 service-section"
    >
      	<div className="container">
        	<div className="wow fadeInUp bg-white" data-wow-delay=".2s">
          		<div className="-mx-4 flex flex-wrap row">
					<div className="col-md-6">
						<div className="service-item">
							<div className="section-title-wrap">
								<span className="mb-5 section-subtitle">Service</span>
								<h2 className="mb-6 section-title font-bold">
									Financial Institutions
								</h2>
							</div>

							<div className="w-full">
								<div className="items-center justify-between overflow-hidden lg:flex">
									<div className="w-full">
										<p className="mb-4 text-base leading-relaxed text-body-color">
										The platform incorporates financial institution including banking and non-banking
										financial institutions such as development finance giving them access to high grade
										agricultural assets to transact with.
										</p>
									</div>
								</div>
							</div>	
						</div>		
					</div>

					<div className="col-md-6">
						
					</div>
				</div>				
			</div>
		</div>
    </section>	
	</GenericLayout>
	);
}

export default Services;










