import React, { useEffect, useState, useRef } from "react";
import { Form, Input } from "antd";
import MultiSelectedFormItems from "./MultiSelectedItems";
import NewContact from "./NewContact";
import AgroButton from "../../../../misc/AgroButton";
import $ from 'jquery';

const valuesInit = {
  title: "",
  first_name: "",
  last_name: "",
  other_name: "",
  email: "",
  phone: "",
  role: "",
};

const ContactPersonsComponent = (props) => {
  const { form, getContactPersons, contactPersons, setContactPersons } = props;
  console.log(contactPersons)
  // const [contactPersons, setContactPersons] = useState([]);
  const [values, setValues] = useState(valuesInit);
  const [visibility, setVisibility] = useState(true);
  const newFormRef = useRef();
  const titles = ["Mr", "Mrs", "Miss", "Ms", "Dr"];
  const roles = ["Chairman", "Secretary", "Treasurer", "Officer"];

  const customValidate = (className)=>{
    // console.log(className)
    let inputs =  document.querySelectorAll('.'+className);
    let inputsToArray = Array.apply(null, inputs);
    // console.log(typeof inputsToArray)
    let ans = true;
    for(let i = 0; i <= inputsToArray.length; i++){
      let element = inputsToArray[i];
      console.log(element);
      if(element.value == ''){
       
        element.style.borderColor = 'red';console.log(element.getAttribute('id'))
        // console.log(element.getAttribute('id'));
       
        ans = false;
        break;
      }else{
        // console.log(element.getAttribute('id'));
        ans = true;
      }   
      
    }
 return ans;
   
    
  }

  const createSubmitData = (className)=>{
    // console.log(className)
    let validateFieldInputs = customValidate(className);
   
    // console.log(customValidate(className))
    // return;
    if(validateFieldInputs == true){
      console.log('yyy')
      const finalArr = [];
      contactPersons?.forEach(function(currentValue, index){

        const initObj = {};

        let inputs = document.querySelectorAll('.'+className);

        inputs.forEach(function(element, count){

          // if(element.getAttribute('id') == `contact_title[${index}]`){
          //   initObj.title = element.value;
          // }

          initObj.title = element.getAttribute('id') == `contact_title[${index}]` && element.value;

          initObj.first_name = element.getAttribute('id') == `contact_first_name[${index}]` && element.value;

          initObj.surname = element.getAttribute('id') == `contact_last_name[${index}]` && element.value;

          initObj.email = element.getAttribute('id') == `contact_email[${index}]` && element.value;

          initObj.phone = element.getAttribute('id') == `contact_phone[${index}]` && element.value;

          initObj.role = element.getAttribute('id') == `contact_role[${index}]` && element.value;

        });

        console.log(initObj);

      });
      return;
    }else{
      console.log('lll');
      return;
    }
    
  }

  $('.contact-field').on('input', function(){
    $(this).css('border-color', 'rgb(0 0 0 / 80%)');
    
  });

 

  

  // const addContactPerson = async () => { 
  //   console.log($('#contact_title[0]').val());
  //   if(customValidate("contact-field")){
     
  //   }else{
  //     return;
  //   }
  
  //   //  let newContacts = [];
  //     // console.log(contactPersons)
  //       contactPersons?.forEach(function(currentValue, index){
  //         customValidate()
  //         return;
  //         // newContacts = newContacts.concat(
  //         //   [
  //         //     `contact_title[${index}]`,
  //         //     `contact_first_name[${index}]`,
  //         //     `contact_last_name[${index}]`,
  //         //     `contact_email[${index}]`,
  //         //     `contact_phone[${index}]`,
  //         //     `contact_role[${index}]`,
  //         //   ]);
            
  //           // if(newContacts.length == contactPersons.length){
  //             // console.log(newContacts);
  //           // }
  //         // validateObject = {
  //         //   title:`contact_title[${index}]`,
  //         //   first_name:`contact_first_name[${index}]`,
  //         //   surname:`contact_last_name[${index}]`,
  //         //   email:`contact_email[${index}]`,
  //         //   phone: `contact_phone[${index}]`,
  //         //   role:`contact_role[${index}]`,
  //         // }

  //         // console.log(index)
  //       form
  //       .validateFields([
  //         `contact_title[${index}]`,
  //         `contact_first_name[${index}]`,
  //         `contact_last_name[${index}]`,
  //         `contact_email[${index}]`,
  //         `contact_phone[${index}]`,
  //         `contact_role[${index}]`,
  //       ])
  //       .then((vals) => {
  //         const data = {
  //           title: vals[`contact_title[${index}]`],
  //           first_name: vals[`contact_first_name[${index}]`],
  //           surname: vals[`contact_last_name[${index}]`],
  //           email: vals[`contact_email[${index}]`],
  //           phone: vals[`contact_phone[${index}]`],
  //           role: vals[`contact_role[${index}]`],
  //           // name: vals[`contact_title[${index}]`], ${vals?.contact_first_name} ${vals?.contact_last_name}`,
  //         };

  //         // console.log(data)
  //         // newContacts = newContacts[index]=data
  //         // newContacts.push(data)
  //         // console.log(index);
  //         // console.log(newContacts);
  //         // reset();
  //         // console.log(data);
  //         // if(newContacts.length == contactPersons.length){
  //         //   console.log(newContacts);
  //         // }else{
  //         //   console.log(newContacts);
  //         // }
          
  //         // [...contactPersons, { ...data }]
  //         // setContactPersons(newContacts);
  //         // reset();
  //       })
  //       .catch((error) => {
  //         console.log(currentValue)
  //         console.log(error)
  //       });
      
         
  //     })
  //     return 
    
  //   // if(newContacts.length > 0){
      
  //       // setContactPersons(newContacts);
  //       // reset();
  //   // }
  //   // setContactPersons(newContacts);
  //   // reset();
  //   // form
  //   //   .validateFields([
  //   //     "contact_title",
  //   //     "contact_first_name",
  //   //     "contact_last_name",
  //   //     "contact_other_name",
  //   //     "contact_email",
  //   //     "contact_phone",
  //   //     "contact_role",
  //   //   ])
  //   //   .then((vals) => {
  //   //     const data = {
  //   //       title: vals?.contact_title,
  //   //       first_name: vals?.contact_first_name,
  //   //       last_name: vals?.contact_last_name,
  //   //       other_name: vals?.contact_other_name,
  //   //       email: vals?.contact_email,
  //   //       phone: vals?.contact_phone,
  //   //       role: vals?.contact_role,
  //   //       name: `${vals?.contact_title}, ${vals?.contact_first_name} ${vals?.contact_last_name}`,
  //   //     };
  //   //     // setContactPersons([...contactPersons, { ...data }]);
  //   //     reset();
  //   //   })
  //   //   .catch((error) => {});
  // };

  const reset = () => {
    form.setFieldsValue({
      contact_title: "",
      contact_first_name: "",
      contact_last_name: "",
      contact_other_name: "",
      contact_email: "",
      contact_phone: "",
      contact_role: "",
    });
    setValues({ ...values, ...valuesInit });
    setVisibility(false);
  };

  const remove = (index) => {
    contactPersons.splice(index, 1);
    setContactPersons(contactPersons.filter((_, i) => i !== index));
  };

  const showNewForm =()=>{
    newFormRef.current.style.display = 'block';
  }
  const hideNewForm =()=>{
    newFormRef.current.style.display = 'none';
  }

  return (
    <>
      {visibility ? (
        
        <>
          {contactPersons?.map((person, index)=>(
             <div className="flex items-center justify-start md:justify-between flex-wrap w-full" key={person.id} style={{ borderBottom:"1px solid rgb(61, 216, 0)", marginTop:"15px" }}>
                <div className="w-full md:w-[12%] lg:my-0">
                  <p className="form-label text-xs">Title</p>
                  <Form.Item
                    name={`contact_title[${index}]`}
                    rules={[{ required: true, message: "Required" }]}
                    initialValue={person.title}
                  >
                    <select className="form-field__input__2 contact-field bg-white form-field__sel">
                      <option value={""}>Select Title</option>
                      {titles?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </Form.Item>
                </div>

                <div className="w-full md:w-[25%] lg:my-0">
                  <p className="form-label text-xs">First Name</p>
                  <Form.Item
                    name={`contact_first_name[${index}]`}
                    initialValue={person.first_name}
                    rules={[
                      { required: true, message: "Required" },
                      { min: 3, message: "Must be at least 3 characters" },
                    ]}
                  >
                    <Input className="form-field__input__2 contact-field" placeholder="Ojo" />
                  </Form.Item>
                </div>

                <div className="w-full md:w-[25%] lg:my-0">
                  <p className="form-label text-xs">Last Name</p>
                  <Form.Item
                    name={`contact_last_name[${index}]`}
                    initialValue={person.surname}
                    rules={[
                      { required: true, message: "Required" },
                      { min: 3, message: "Must be at least 3 characters" },
                    ]}
                  >
                    <Input className="form-field__input__2 contact-field" placeholder="Wale" />
                  </Form.Item>
                </div>

                {/* <div className="w-full md:w-[25%] lg:my-0">
                  <p className="form-label text-xs">Other Name</p>
                  <Form.Item
                    name={"contact_other_name"}
                    rules={[{ min: 3, message: "Must be at least 3 characters" }]}
                  >
                    <Input className="form-field__input__2 contact-field" placeholder="Wale" />
                  </Form.Item>
                </div> */}

                <div className="w-full md:w-[29%] lg:my-0">
                  <p className="form-label text-xs">Email</p>
                  <Form.Item
                    name={`contact_email[${index}]`}
                    initialValue={person.email}
                    rules={[
                      { required: true, message: "Required" },
                      { type: "email", message: "Invalid Email" },
                    ]}
                  >
                    <Input
                      className="form-field__input__2 contact-field"
                      placeholder="matt@agroclerk.com"
                    />
                  </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0">
                  <p className="form-label text-xs">Phone</p>
                  <Form.Item
                    name={`contact_phone[${index}]`}
                    initialValue={person.phone}
                    rules={[
                      { required: true, message: "Required" },
                      { min: 8, message: "Must be at least 8 character" },
                    ]}
                  >
                    <Input
                      type="tel"
                      className="form-field__input__2 contact-field"
                      placeholder="08012345678"
                    />
                  </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0">
                  <p className="form-label text-xs">Role</p>
                  <Form.Item
                    name={`contact_role[${index}]`}
                    rules={[{ required: true, message: "Required" }]}
                    initialValue={person.role}
                  >
                    <select className="form-field__input__2 contact-field bg-white form-field__sel">
                      <option value={""}>Select Role</option>
                      {roles?.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </Form.Item>
                </div>
              </div>
             
          ))}

          <NewContact titles={titles} roles={roles} Form={Form} Input={Input} ref={{ newFormRef:newFormRef}}/>
          

          <div className="w-full my-4 flex justify-end gap-x-6">
            {/* {contactPersons.length ? (
              <AgroButton
                className={
                  "bg-red-500 text-[#ffffff] hover:bg-red-800 text-[10px]"
                }
                text={"Close"}
                type="button"
                onClick={() => setVisibility(false)}
              />
            ) : (
              <></>
            )} */}
            <AgroButton
              primary
              text={"Save Contact Person"}
              type="button"
              onClick={()=>{createSubmitData("contact-field")}}
              className={"text-[10px]"}
            />

            <AgroButton
              primary
              text={"Add New Contact"}
              type="button"
              onClick={showNewForm}
              className={"text-[10px]"}
            />
          </div>
        </>
      ) : (
        <div className="w-full mb-4">
          <AgroButton
            primary
            text={"Add New Contact"}
            type="button"
            onClick={() => setVisibility(true)}
            className={"text-[10px]"}
          />
        </div>
      )}
      {contactPersons.length > 0 ?  (
        <MultiSelectedFormItems
          items={contactPersons}
          prop={"first_name"}
          remove={remove}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ContactPersonsComponent;
