import React from "react";
import GenericLayout from "../../containers/layouts/GenericLayout";



function About() {
	return (
		<GenericLayout>
		<section 
			id="header" 
			className="hero-header" 
			style={{backgroundImage: "url(/img/about_hero.jpg)" }}>

			<div className="container">
				<div className="wow fadeInDown hero-wrap text-center" data-wow-delay=".2s">
					<div className="page-title text-white">
						<h2 className="title text-white">About Us</h2>
						<p className="description">Transforming Africa’s agriculture landscape involves fixing the 
						agricultural value chains so that various stakeholders can engage with confidence across 
						commercially healthy agricultural value chains.</p>
					</div>
					<div className="page-breadcrumbs">
						<div className="breadcrumbs text-center">
							<ul>
								<li className="base"><a href="#">Home</a></li>
								<li className="active"><a href="#">About Us</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>

		</section>
		<section
      		id="about"
      		className="page-content pt-20 pb-20"
    	>
      	<div className="container">
        	<div className="wow fadeInUp bg-white" data-wow-delay=".2s">
          		<div className="-mx-4 flex flex-wrap row">
					<div className="col-md-6">
						<div className="section-title-wrap">
							<span className="mb-5 section-subtitle">About Us</span>
							<h2 className="mb-6 section-title font-bold">
								Digitizing the agricultural value chain.
							</h2>
						</div>

						<div className="w-full pt-10">
							<div className="items-center justify-between overflow-hidden lg:flex">
								<div className="w-full">
									<p className="mb-4 text-base leading-relaxed text-body-color">
										Transforming Africa's agriculture landscape involves fixing the agricultural value chains so that
										various stakeholders can engage with confidence across commercially healthy agricultural value
										chains. A first step to achieve this is to create a controlled environment for players in which the
										agricultural value chain is well structured. For this to be successfully achieved, it is imperative
										that technology is leveraged upon to enhance rapid information dissemination and service
										delivery within the agricultural sector.
									</p>
									<p className="mb-4 text-base leading-relaxed text-body-color">
										The African agricultural landscape is largely unstructured characterized by a disconnected value
										chain. The value chain is further plagued with poor infrastructure, inadequate access to credit,
										and low level of leverage in information technology.AgroClerk is focused on fixing some identified 
										gaps in the agricultural value chain by leveraging on technology.
									</p>
									<p className="mb-4 text-base leading-relaxed text-body-color">
										The platform offers various benefits including but not limited to technical assistance, access to
										loans and credit, indexed directory services, rich agricultural blog, micro-ecommerce website,
										marketplace, aggregation, cooperative services, evacuation services, records management, crowdfunding etc.
									</p>
								</div>
							</div>
						</div>			
					</div>

					<div className="col-md-5 md-offset-1">
						<div className="image-wrapper mt-20">
							<img src={`/img/about_intro.jpg`} alt="Digitizing the agricultural value chain" />
						</div>
					</div>
				</div>				
			</div>
		</div>
    </section>
	<section
		id="offerings"
		className="page-content pb-20 pt-20 bg-grey"
	>
		<div className="container">
		<div className="wow fadeInUp" data-wow-delay=".2s">
          		<div className="-mx-4 flex flex-wrap row">
				  <	div className="col-md-5">
				  		<div className="section-title-wrap">
							<span className="mb-5 section-subtitle">What We Offer</span>
							<h2 className="mb-6 section-title font-bold">
								Our Services
							</h2>
						</div>
						
				  		<div className="image-wrapper mt-20">
							<img src={`/img/tech_farming.jpg`} alt="Our offerings" />
						</div>
						
				  </div>

					<div className="col-md-6 md-offset-1">
						<div className="items-center justify-between overflow-hidden lg:flex">								
							<p className="mb-5 text-base leading-relaxed text-body-color">
								AgroClerk is an electronic agricultural platform that optimizes interaction between various
								aspects of the value chain and also automates activities within each value chain. The platform is
								available both on web and mobile channels.
							</p>
						</div>
						<div className="row">
							
							<div className="overflow-hidden  lg:flex">
							
								<div className="col-md-6 list-block">
									<h4>Core Services</h4>
									<p className="mb-4 text-base leading-relaxed text-body-color">
										The platform provide the following core services;
									</p>
									<ul className="mb-4 text-base leading-relaxed text-body-color">
										<li>Data Management Services</li>
										<li> Media Services
											<ul className="ml-6">
												<li>Targeted Adverts</li>
												<li> Blogs and Forum</li>
											</ul>
										</li>
										<li> Field Services
											<ul className="ml-6">
												<li>Aggregation</li>
												<li>Offtakers</li>
												<li>Anchor Borrowers</li>
												<li>Programs</li>
											</ul>
										</li>
										<li> Group Services
											<ul className="ml-6">
												<li>Associations</li>
												<li>Cooperatives</li>
												<li>Events</li>
												<li>Tenders</li>
												<li>Messaging</li>
											</ul>
										</li>
										<li>Trading</li>
										<li>Records Management</li>
										<li>Financial Services</li>
									</ul>
								</div>
								<div className="col-md-6 list-block">
									<h4>Target Stakeholders</h4>
									<p className="mb-4 text-base leading-relaxed text-body-color">
										Agroclerks targets the following players in the agribusiness space;
									</p>
									<ul className="text-base ">
										<li> Agriculture Practitioners
											<ul className=" ml-6">
												<li>Input Suppliers</li>
												<li>Producers</li>
												<li>Anchor Borrowers</li>
												<li>Processors</li>
												<li>Traders</li>
												<li>Storage Providers</li>
												<li>etc</li>
											</ul>
										</li>
										<li>Government Institutions</li>
										<li>Financial Organization</li>
										<li>Agriculture Consultants</li>
										<li>Associations and Cooperatives</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
		</GenericLayout>
	);
}

export default About;
