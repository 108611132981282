import React from 'react'
import GenericLayout from '../../containers/layouts/GenericLayout'

const Pricing = () => {
  return (
    <GenericLayout>
        <section 
			id="header" 
			className="hero-header" 
			style={{backgroundImage: "url(/img/pricing_hero.jpg)" }}>

			<div className="container">
				<div className="wow fadeInDown hero-wrap text-center" data-wow-delay=".2s">
					<div className="page-title text-white">
						<h2 className="title text-white">Pricing</h2>
						<p className="description">We offer quality services at market competitive prices,  check out our prices below.</p>
					</div>
					<div className="page-breadcrumbs">
						<div className="breadcrumbs text-center">
							<ul>
								<li className="base"><a href="#">Home</a></li>
								<li className="active"><a href="#">Pricing</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>

		</section>
    <section
id="pricing"
className="page-content pt-20 pb-20"
>
<div className="container">
<div className="wow fadeInUp bg-white" data-wow-delay=".2s">
 <div className="-mx-4 flex flex-wrap">
   <div className="w-full px-4">
     <div
       className="items-center justify-between overflow-hidden"
     >

        <div className="min-h-screen flex justify-center items-center pt-10 pb-20">
        <div className="">
            <div className="text-center font-semibold">
                <h1 className="text-5xl">
                    <span className="text-green-700 tracking-wide">Flexible </span>
                    <span>Plans</span>
                </h1>
                <p className="pt-6 text-xl text-gray-400 font-normal w-full px-8 md:w-full">
                    Choose a plan that works best for you and<br/> your team.
                </p>
            </div>
            <div className="pt-24 flex flex-row">
             
                <div className="w-96 p-8 bg-white text-center rounded-3xl pr-16 shadow-xl">
                    <h1 className="text-black font-semibold text-2xl">Basic</h1>
                    <p className="pt-2 tracking-wide">
                        <span className="text-gray-400 align-top">$ </span>
                        <span className="text-3xl font-semibold">10</span>
                        <span className="text-gray-400 font-medium">/ user</span>
                    </p>
                    <hr className="mt-4 border-1" />
                    <div className="pt-8">
                        <p className="font-semibold text-gray-400 text-left">
                            <span className="material-icons align-middle">
                                done
                            </span>
                            <span className="pl-2">
                                Get started with <span className="text-black">messaging</span>
                            </span>
                        </p>
                        <p className="font-semibold text-gray-400 text-left pt-5">
                            <span className="material-icons align-middle">
                                done
                            </span>
                            <span className="pl-2">
                                Flexible <span className="text-black">team meetings</span>
                            </span>
                        </p>
                        <p className="font-semibold text-gray-400 text-left pt-5">
                            <span className="material-icons align-middle">
                                done
                            </span>
                            <span className="pl-2">
                                <span className="text-black">5 TB</span> cloud storage
                            </span>
                        </p>

                        <a href="#" className="">
                            <p className="w-full py-4 bg-green-600 mt-8 rounded-xl text-white">
                                <span className="font-medium">
                                    Choose Plan
                                </span>
                            
                            </p>
                        </a>
                    </div>
                </div>
               
                <div className="w-80 p-8 bg-green-700 text-center rounded-3xl text-white border-4 shadow-xl border-white transform scale-125">
                    <h1 className="text-white font-semibold text-2xl">Startup</h1>
                    <p className="pt-2 tracking-wide">
                        <span className="text-gray-400 align-top">$ </span>
                        <span className="text-3xl font-semibold">24</span>
                        <span className="text-gray-400 font-medium">/ user</span>
                    </p>
                    <hr className="mt-4 border-1 border-gray-600" />
                    <div className="pt-8">
                        <p className="font-semibold text-gray-400 text-left">
                            <span className="material-icons align-middle">
                                done
                            </span>
                            <span className="pl-2">
                                All features in <span className="text-white">Basic</span>
                            </span>
                        </p>
                        <p className="font-semibold text-gray-400 text-left pt-5">
                            <span className="material-icons align-middle">
                                done
                            </span>
                            <span className="pl-2">
                                Flexible <span className="text-white">call scheduling</span>
                            </span>
                        </p>
                        <p className="font-semibold text-gray-400 text-left pt-5">
                            <span className="material-icons align-middle">
                                done
                            </span>
                            <span className="pl-2">
                                <span className="text-white">15 TB</span> cloud storage
                            </span>
                        </p>

                        <a href="#" className="">
                            <p className="w-full py-4 bg-green-600 mt-8 rounded-xl text-white">
                                <span className="font-medium">
                                    Choose Plan
                                </span>
                            
                            </p>
                        </a>
                    </div>
                    <div className="absolute top-4 right-4">
                        <p className="bg-green-700 font-semibold px-4 py-1 rounded-full uppercase text-xs">Popular</p>
                    </div>
                </div>
              
                <div className="w-96 p-8 bg-white text-center rounded-3xl pl-16 shadow-xl">
                    <h1 className="text-black font-semibold text-2xl">Enterprise</h1>
                    <p className="pt-2 tracking-wide">
                        <span className="text-gray-400 align-top">$ </span>
                        <span className="text-3xl font-semibold">35</span>
                        <span className="text-gray-400 font-medium">/ user</span>
                    </p>
                    <hr className="mt-4 border-1" />
                    <div className="pt-8">
                        <p className="font-semibold text-gray-400 text-left">
                            <span className="material-icons align-middle">
                                done
                            </span>
                            <span className="pl-2">
                                All features in <span className="text-black">Startup</span>
                            </span>
                        </p>
                        <p className="font-semibold text-gray-400 text-left pt-5">
                            <span className="material-icons align-middle">
                                done
                            </span>
                            <span className="pl-2">
                                Growth <span className="text-black">oriented</span>
                            </span>
                        </p>
                        <p className="font-semibold text-gray-400 text-left pt-5">
                            <span className="material-icons align-middle">
                                done
                            </span>
                            <span className="pl-2">
                                <span className="text-black">Unlimited</span> cloud storage
                            </span>
                        </p>

                        <a href="#" className="">
                            <p className="w-full py-4 bg-green-600 mt-8 rounded-xl text-white">
                                <span className="font-medium">
                                    Choose Plan
                                </span>
                             
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
     </div>
   </div>
 </div>
</div>
</div>
</section>
</GenericLayout>
);
}


export default Pricing