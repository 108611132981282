import React, { useEffect, useState } from "react";
import { Form } from "antd";
import useCountriesStates from "../../../../utils/hooks/auth/useCountriesStates";
import useFarmerData from "../../../../utils/hooks/user/useFarmerData";
import { AgroButton, InlineError } from "../../../../components";
import AddCorporateFarmer from "./AddCorporateFarmer";
import AddIndividualFarmer from "./AddIndividualFarmer";
import _ from "lodash";
import useGeneric from "../../../../utils/hooks/user/useGeneric";
import { message } from "antd";
import CorporateInfo from "./CorporateInfo";
import LocationInfo from "./LocationInfo";
import ContactPersonInfo from "./ContactPersonInfo";
import BankDetailsInfo from "./BankDetailsInfo";
import IndividualInfo from "./IndividualInfo";

const AddFarmer = ({ completed }) => {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  // const [current, setCurrent] = useState(1);
  const [buttonText, setButtonText] = useState("Create Farmer");
  const [buttonText2, setButtonText2] = useState("Cancel");
  const { countriesData, getCountries } = useCountriesStates();
  const [farmerType, setFarmerType] = useState(null);
  const [farmRecordId, setFarmRecordId] = useState(null);

  const {
    createFarmerLoading,
    createFarmerFailure,
    setCreateFarmerFailure,
    createFarmer,
    createLocationLoading,
    createLocationFailure,
    setCreateLocationFailure,
    createLocation,
    createContactPersonLoading,
    createContactPersonFailure,
    setCreateContactPersonFailure,
    createContactPerson,
    createBankDetailsLoading,
    createBankDetailsFailure,
    setCreateBankDetailsFailure,
    createBankDetails,
  } = useFarmerData();

  const { categoriesData, getCategories } = useGeneric();
  const [focuses, setFocuses] = useState([]);
  const [locations, setLocations] = useState([]);
  const [contactPersons, setContactPersons] = useState([]);
  const success = "Successful";
  const error = "An error has occured";

  const next = () => {
    setCurrent(current + 1);
  };

  const onSubmit = (values) => {
    if (current === 0) {
      const focusItems = focuses.map((f) => f.item);
      const data = {
        company_name: values?.company_name ?? "",
        first_name: values?.first_name ?? "",
        last_name: values?.last_name ?? "",
        middle_name: values?.middle_name ?? "",
        phone: values?.phone_number ?? "",
        email: values?.email ?? "",
        website: values?.website ?? "",
        employee_count: values?.number_of_employees ?? "",
        type_id: values?.farmer_type,
        focusItems: focusItems,
      };
      const formData = new FormData();
      _.forEach(data, (value, key) => {
        // console.log("MY DATATAT", data)
        // console.log("MY DATATAT Value", value)
        // console.log("MY DATATAT KEEEY", key)

        if (typeof value === "object") {
          if (value?.length) {

            for (let i = 0; i < value.length; i++) {
              _.forEach(value[i], (item, prop) => {
                console.log("value", value[i])
                console.log("items", item)
                console.log("key", prop)
                if (item && key !== "focusItems") {
                  formData.append(`${key}[${i}][${prop}]`, item);
                }
                if (item && key === "focusItems") {
                  // focusItems?.forEach((focusId, i, data) => {
                  //   //  formData.append(`${key}[${i}]`, item);
                  //   formData.append(`${key}[${i}]`, focusId);

                  // })

                  // console.log("FOOCCCUSSS IIIITEEEEEM", focusItems)

                  // alert("individual farm record")
                  formData.append(`${key}[${i}]`, value[i]);


                  // console.log("MYYYYYYYYYYYY ITEEEEMS", `${key}[${i}]`, item)
                }
              });
            }
          }
        } else {
          formData.append(key, value);
        }
      });

      createFarmer(formData).then((data) => {
        if (data?.success) {
          setFarmRecordId(data?.data?.farmer_record?.id);
          message.success(data?.message || success);
          next();
        } else {
          message.error(data?.message || error);
        }
      });
    }

    if (current === 1) {
      const addresses = locations.map((l) => {
        return {
          city_id: l.city,
          country_id: l.country,
          state_id: l.state,
          address: l.address,
        };
      });
      const data = {
        addresses: addresses,
      };
      const formData = new FormData();
      _.forEach(data, (value, key) => {
        if (typeof value === "object") {
          if (value?.length) {
            for (let i = 0; i < value.length; i++) {
              _.forEach(value[i], (item, prop) => {
                formData.append(`${key}[${i}][${prop}]`, item);
              });
            }
          }
        } else {
          formData.append(key, value);
        }
      });

      createLocation(formData, farmRecordId).then((data) => {
        if (data?.success) {
          message.success(data?.message || success);
          next();
        } else {
          message.error(data?.message || error);
        }
      });
    }

    if (current === 2 && farmerType === "2") {
      const contacts = values?.contact_email
        ? [
          ...contactPersons,
          {
            email: values?.contact_email,
            first_name: values?.contact_first_name,
            last_name: values?.contact_last_name,
            other_name:
              values?.contact_other_name !== "undefined" ||
                values?.contact_other_name !== undefined
                ? values?.contact_other_name
                : "",
            phone: values?.contact_phone,
            title: values?.contact_title,
          },
        ]
        : contactPersons;
      const data = {
        contacts: contacts,
      };
      const formData = new FormData();
      _.forEach(data, (value, key) => {
        if (typeof value === "object") {
          if (value?.length) {
            for (let i = 0; i < value.length; i++) {
              _.forEach(value[i], (item, prop) => {
                formData.append(`${key}[${i}][${prop}]`, item);
              });
            }
          }
        } else {
          formData.append(key, value);
        }
      });
      createContactPerson(formData, farmRecordId).then((data) => {
        if (data?.success) {
          message.success(data?.message || success);
          next();
        } else {
          message.error(data?.message || error);
        }
      });
    }

    if (current === 3 || (current === 2 && farmerType === "1")) {
      const data = {
        bank_name: values?.bank ?? "",
        bank_account_name: values?.account_name ?? "",
        bank_account_number: values?.account_number ?? "",
        bank_bvn: values?.bvn ?? "",
      };
      const formData = new FormData();
      _.forEach(data, (value, key) => {
        if (typeof value === "object") {
          if (value?.length) {
            for (let i = 0; i < value.length; i++) {
              _.forEach(value[i], (item, prop) => {
                formData.append(`${key}[${i}][${prop}]`, item);
              });
            }
          }
        } else {
          formData.append(key, value);
        }
      });

      createBankDetails(formData, farmRecordId).then((data) => {
        if (data?.success) {
          message.success(data?.message || success);
          completed();
        } else {
          message.error(data?.message || error);
        }
      });
    }
  };

  const handleFarmerTypeChange = (e) => {
    const _farmerType = e.target.value;
    setFarmerType(_farmerType);
  };

  const closeErrors = () => {
    setCreateFarmerFailure(null);
    setCreateLocationFailure(null);
    setCreateContactPersonFailure(null);
    setCreateBankDetailsFailure(null);
  };

  useEffect(() => {
    getCountries();
    getCategories();
  }, []);

  const corporateSteps = [
    {
      title: "Create Farmer",
      content: (
        <CorporateInfo
          form={form}
          categories={categoriesData}
          setFocuses={(focuses) => setFocuses(focuses)}
        />
      ),
    },
    {
      title: "Farmer Location",
      content: (
        <LocationInfo
          form={form}
          countries={countriesData}
          setLocations={(locations) => setLocations(locations)}
        />
      ),
    },
    {
      title: "Contact Person",
      content: (
        <ContactPersonInfo
          form={form}
          setContactPersons={(contactPersons) =>
            setContactPersons(contactPersons)
          }
        />
      ),
    },
    {
      title: "Bank Details",
      content: <BankDetailsInfo />,
    },
  ];


  const individualSteps = [
    {
      title: "Create Farmer",
      content: (
        <IndividualInfo
          form={form}
          categories={categoriesData}
          setFocuses={(focuses) => setFocuses(focuses)}
        />
      ),
    },
    {
      title: "Farmer Location",
      content: (
        <LocationInfo
          form={form}
          countries={countriesData}
          setLocations={(locations) => setLocations(locations)}
        />
      ),
    },
    {
      title: "Bank Details",
      content: <BankDetailsInfo />,
    },
  ];
  useEffect(() => {
    if (current !== 0) {
      if ((farmerType === "1" && (current == individualSteps.length - 2)) || (farmerType === "2" && (current == corporateSteps.length - 2))) {
        setButtonText("Save and Continue to Bank");
        if (locations.length) {
          setButtonText2("Complete");
        }
      } else {
        setButtonText("Continue");
        setButtonText2("Cancel");
      }
    }

  }, [corporateSteps.length, current, farmerType, individualSteps.length, locations]);

  return (
    <div>
      <InlineError
        control={
          createFarmerFailure ||
          createLocationFailure ||
          createContactPersonFailure ||
          createBankDetailsFailure
        }
        message={
          createFarmerFailure ||
          createLocationFailure ||
          createContactPersonFailure ||
          createBankDetailsFailure
        }
        onClose={() => closeErrors()}
      />
      <Form form={form} onFinish={onSubmit} autoComplete="on" className="mt-5">
        <div className="flex items-center flex-wrap w-full">
          <div className="w-full lg:my-0 ">
            <div className="w-full lg:w-1/3 lg:my-0 ">
              <p className="form-label">Farmer Type</p>
              <Form.Item
                onChange={handleFarmerTypeChange}
                name="farmer_type"
                rules={[{ required: true, message: "Required" }]}
              >
                <select className="form-field__input__2 form-field__sel bg-white">
                  <option>Select Farmer Type</option>
                  <option value={"2"}>Corporate</option>
                  <option value={"1"}>Individual</option>
                </select>
              </Form.Item>
            </div>
          </div>
        </div>
        {farmerType === "2" ? (
          <AddCorporateFarmer
            current={current}
            steps={corporateSteps}
          />
        ) : farmerType === "1" ? (
          <AddIndividualFarmer
            steps={individualSteps}
            current={current}
          />
        ) : (
          <></>
        )}

        {farmerType ? (
          <div className="flex justify-end mt-4 mb-1 gap-x-4">
            {current ? (
              <AgroButton
                disabled={
                  createFarmerLoading ||
                  createLocationLoading ||
                  createContactPersonLoading ||
                  createBankDetailsLoading
                }
                text={buttonText2}
                type="button"
                className={"px-10"}
                onClick={() => completed()}
              />
            ) : <></>}

            <AgroButton
              loading={
                createFarmerLoading ||
                createLocationLoading ||
                createContactPersonLoading ||
                createBankDetailsLoading
              }
              disabled={
                createFarmerLoading ||
                createLocationLoading ||
                createContactPersonLoading ||
                createBankDetailsLoading || (current === 1 && !locations.length)
              }
              primary
              text={
                createFarmerLoading ||
                  createLocationLoading ||
                  createContactPersonLoading ||
                  createBankDetailsLoading
                  ? "Please wait..."
                  : buttonText
              }
              type="submit"
              className={"px-10"}
            />
          </div>
        ) : (
          <></>
        )}
      </Form>
    </div>
  );
};

export default AddFarmer;
