import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CgShoppingCart } from "react-icons/cg";
import { MdMenu, MdOutlineClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { navItems } from "../../utils/misc/navData";
import { AgroButton } from "../../components";
import { Dropdown } from "antd";
import { logoutUser } from "../../slices/globalSlice";

function Navbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.global);
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);

  const currentPath = window.document.location.pathname;  
  const handleUserAuth = () => {
    if (!user) {
      navigate("/auth/signin");
    } else {
      localStorage.removeItem("agro-user");
      dispatch(logoutUser());
      navigate("/auth/signin");
    }
  };
  const [scroll, setScroll] = useState(false)
    useEffect(() => {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 50)
      })
  }, [])
  return ( 
    <Nav className={scroll ? "z-20 shadow-xl flex justify-between items-center pl-4 pr-3 py-[6px] h-[60px] lg:h-[80px] sticky top-0 left-0 bg-white" : "z-20 flex justify-between items-center pl-4 pr-3 py-[6px] h-[60px] lg:h-[80px] top-0 left-0 fixed bg-green-1"}>
      <div className="container flex items-center">
        <div className="cs-logo">
          <NavLink to="/">
            <img src="/agro-logo-w.png" alt="logo" className="object-contain w-20 h-20 lg:w-24 lg:h-24 cursor-pointer main-logo" />
            <img src="/agro-logo.png" alt="logo" className="object-contain w-20 h-20 lg:w-24 lg:h-24 cursor-pointer sticky-logo" />
          </NavLink>
        </div>
        <div className="mid-container-wrap">
          <ul className={`${sidebarIsOpen && "active"} lg:space-x-7 main-nav`}>
            {navItems.map(({ title, path, dropdown }) => (
              <div key={title}>
                <li>
                  {dropdown ? (
                    <Dropdown overlay={dropdown} visibled placement="bottomLeft">
                      <NavLink
                        style={({ isActive }) => ({
                          color:
                            isActive || currentPath === path ? "#95DF3B" : "#ffffff",
                        })}
                        to={path}
                        className="group nav-links flex items-center px-3 py-2 text-[16px] lg:text-lg tracking-wide"
                      >
                        {title}
                        <IoIosArrowDown
                          className="ml-1 group-hover:rotate-180 font-semibold transition-all duration-300"
                          size="15px"
                        />
                      </NavLink>
                    </Dropdown>
                  ) : (
                    <NavLink
                      style={({ isActive }) => ({
                        color:
                          isActive || currentPath === path ? "#95DF3B" : "#ffffff",
                      })}
                      to={path}
                      className="group nav-links flex items-center px-3 py-2 text-[16px] lg:text-lg tracking-wide"
                    >
                      {title}
                    </NavLink>
                  )}
                </li>
              </div>
            ))}
            {user ? (
              <NavLink
                to={"/user/home"}
                className="group nav-links flex items-center px-3 py-2 text-[16px] lg:text-lg tracking-wide"
              >
                {"Dashboard"}
              </NavLink>
            ) : (
              <></>
            )}

            <AgroButton
              onClick={handleUserAuth}
              primary
              className="lg:ml-10 mr-4 lg:mr-0"
              hasBorder
              text={user ? "Sign Out" : "Get Started"}
            />
          </ul>
        </div>
      
        <div className="flex items-center justify-center">
          <span
            className="toggle-container"
            onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
          >
            {sidebarIsOpen ? (
              <MdOutlineClose className="grn-txt h-8 w-8 cursor-pointer" />
            ) : (
              <MdMenu className="grn-txt h-8 w-8 cursor-pointer" />
            )}
          </span>
          <div className="relative flex items-center justify-center  cursor-pointer mx-3">
            <NavLink to="/cart">
              <span className="absolute h-[14px] w-[14px] rounded-full nav-bg text-white flex items-center justify-center p-[6px] py-[7px] text-[8px] -right-[5px] -top-[2px]">
                99
              </span>
              <CgShoppingCart className="h-[22px] w-[22px] grn-txt" />
            </NavLink>
          </div>
        </div>
      </div>
    </Nav>
  );
}

export default Navbar;

const Nav = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  .toggle-container {
    display: none;
  }

  .mid-container {
    width: 100%;
    ul {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .nav-links:hover {
        color: #009900 !important;
      }
    }
  }

  @media (max-width: 768px) {
    position: relative;

    .mid-container {
      width: 100%;
      padding: 20px;
      justify-content: flex-end;
    }

    ul {
      background-color: #fff;
      position: absolute;
      top: 60px;
      left: -110%;
      width: 100%;
      height: calc(100vh - 70px);
      transition: var(--trans);
      flex-direction: column;
      justify-content: flex-start !important;
      align-items: flex-end !important;
      padding-bottom: 100px;
      padding-right: 20px;

      li {
        margin: 5px;
      }

      &.active {
        left: 0;
      }
    }
    .toggle-container {
      display: inline;
    }
  }
`;
