import React from "react";
import { Steps } from "antd";

const { Step } = Steps;

const AddIndividualFarmer = (props) => {
  const { steps, current } =
    props;

  return (
    <div>
      <Steps size={"small"} current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </div>
  );
};

export default AddIndividualFarmer;
