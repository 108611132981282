import {
  AiFillHome,
  AiOutlineFundProjectionScreen,
  AiOutlineAppstoreAdd,
  AiOutlineFileAdd,
  AiFillAccountBook,
} from "react-icons/ai";
import { TiGroup } from "react-icons/ti";
import { MdAddTask, MdDashboard } from "react-icons/md";
import { GrGroup } from "react-icons/gr";
import { MdFlightTakeoff } from "react-icons/md";
import { TiAnchorOutline } from "react-icons/ti";
import { CgMediaLive } from "react-icons/cg";
import { FaUserFriends } from "react-icons/fa";
import {
  GiPayMoney,
  GiReceiveMoney,
  GiFarmer,
  GiProgression,
  GiHoneycomb,
} from "react-icons/gi";
// import { GoFileSymlinkDirectory } from "react-icons/go";
import { MdForum, MdManageAccounts, MdOutlineInventory } from "react-icons/md";
import { RiAdvertisementFill } from "react-icons/ri";
import { SiDatabricks } from "react-icons/si";
import { VscBook, VscCallIncoming } from "react-icons/vsc";
import {
  UserForum,
  UserDirectories,
  UserAdverts,
  UserEvents,
} from "../../components";
import { MediaServices, UserHome } from "../../pages";

const userNavItems = [
  { title: "Home", Icon: AiFillHome, path: "/user/home" },
  { title: "Farmer Data", Icon: GiFarmer, path: "/user/farmer-data/dashboard" },
  { title: "Media Services", Icon: CgMediaLive, path: "/user/media-services/dashboard" },
  { title: "Field Services", Icon: SiDatabricks, path: "/user/field-services/dashboard" },
  {
    title: "Group Services",
    Icon: FaUserFriends,
    path: "/user/group-services/dashboard",
  },
  { title: "Trading", Icon: GiReceiveMoney, path: "/user/trading/dashboard" },
  {
    title: "Record Management",
    Icon: MdOutlineInventory,
    path: "/user/records/dashboard",
  },
  {
    title: "Technical Services",
    Icon: MdManageAccounts,
    path: "/user/technical-services/dashboard",
  },
  {
    title: "Loans & Grants",
    Icon: GiPayMoney,
    path: "/user/loans-and-grants/dashboard",
  },
];

const farmerDataSidebarItems = [
  {
    title: "Dashboard",
    Icon: MdDashboard,
    path: "/user/farmer-data/dashboard",
  },
  {
    title: "Farmers Record",
    Icon: VscBook,
    path: "/user/farmer-data/farmer-record",
  },
  {
    title: "Groups",
    Icon: GrGroup,
    path: "/user/farmer-data/groups",
  },
  {
    title: "Messaging",
    Icon: VscCallIncoming,
    path: "/user/farmer-data/messaging",
  },
];

const mediaSidebarItems = [
  { title: "Dashboard", Icon: MdDashboard, path: "/user/media-services/dashboard" },
  { title: "Blog", Icon: VscBook, path: "/user/media-services/blog" },
  { title: "Forum", Icon: MdForum, path: "/user/media-services/forum" },
  // {
  //   title: "Directories",
  //   Icon: GoFileSymlinkDirectory,
  //   path: "/user/media-services/directories",
  // },
  {
    title: "Adverts",
    Icon: RiAdvertisementFill,
    path: "/user/media-services/adverts",
  },
  { title: "Events", Icon: GrGroup, path: "/user/media-services/events" },
];

const fieldSidebarItems = [
  {
    title: "Dashboard",
    Icon: MdDashboard,
    path: "/user/field-services/dashboard",
  },
  {
    title: "Aggregation",
    Icon: AiOutlineAppstoreAdd,
    path: "/user/field-services/aggregation",
  },
  {
    title: "Offtake",
    Icon: MdFlightTakeoff,
    path: "/user/field-services/offtake",
  },
  {
    title: "Anchor Borrower",
    Icon: TiAnchorOutline,
    path: "/user/field-services/anchor-borrower",
  },
  {
    title: "Projects",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/field-services/projects",
  },
  {
    title: "Programs",
    Icon: GiProgression,
    path: "/user/field-services/programs",
  },
];

const groupSidebarItems = [
  {
    title: "Dashboard",
    Icon: MdDashboard,
    path: "/user/group-services/dashboard",
  },
  {
    title: "Cooperatives",
    Icon: TiGroup,
    path: "/user/group-services/cooperatives",
  },
  {
    title: "Associations",
    Icon: GiHoneycomb,
    path: "/user/group-services/associations",
  },
];

const tradingSidebarItems = [
  {
    title: "Dashboard",
    Icon: MdDashboard,
    path: "/user/trading/dashboard",
  },
  {
    title: "Market Place",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/trading/market-place",
  },
  {
    title: "Post Buy Leads",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/trading/post-buy-leads",
  },
  {
    title: "Post Sell Leads",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/trading/post-sell-leads",
  },
  {
    title: "Promotion",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/trading/promotion",
  },
];

const recordMgtSidebarItems = [
  {
    title: "Dashboard",
    Icon: MdDashboard,
    path: "/user/records/dashboard",
  },
  {
    title: "Purchase",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/records/purchase",
  },
  {
    title: "Sales",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/records/sales",
  },
  {
    title: "Inventory",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/records/inventory",
  },
  {
    title: "Processing",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/records/processing",
  },
  {
    title: "Reports",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/records/reports",
  },
  {
    title: "Add Customer",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/records/add-customer",
  },
  {
    title: "Add Supplier",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/records/add-supplier",
  },
  {
    title: "Add Product",
    Icon: AiOutlineFundProjectionScreen,
    path: "/user/records/add-product",
  },
];

const technicalSidebarItems = [
  { title: "Dashboard", Icon: MdDashboard, path: "/user/technical-services/dashboard" },
];

const loansSidebarItems = [
  { title: "Dashboard", Icon: MdDashboard, path: "/user/loans-and-grants/dashboard" },
  { title: "Loans", Icon: GiPayMoney, path: "/user/loans-and-grants/loans" },
  { title: "Loan Request", Icon: AiOutlineFileAdd, path: "/user/loans-and-grants/loan-request" },
  {
    title: "Grants",
    Icon: GiReceiveMoney,
    path: "/user/loans-and-grants/grants",
  },
  {
    title: "Grant Request",
    Icon: AiFillAccountBook,
    path: "/user/loans-and-grants/grant-request",
  },
];

const userRoutes = [
  // {
  // 	basePath: "/user/media-services",
  // 	base: <MediaServices />,
  // 	children: [
  // 		{ path: "forum", element: <UserForum /> },
  // 		{
  // 			path: "user-directories",
  // 			element: <UserDirectories />,
  // 		},
  // 		{ path: "adverts", element: <UserAdverts /> },
  // 		{ path: "events", element: <UserEvents /> },
  // 	],
  // },
];

export {
  userNavItems,
  farmerDataSidebarItems,
  mediaSidebarItems,
  fieldSidebarItems,
  groupSidebarItems,
  tradingSidebarItems,
  recordMgtSidebarItems,
  technicalSidebarItems,
  loansSidebarItems,
  userRoutes,
};
