import React, { useEffect, useState } from "react";
import { Form } from "antd";
import MultiSelectedFormItems from "./MultiSelectedItems";
import useGeneric from "../../../../utils/hooks/user/useGeneric";

const Focus = (props) => {
  const {
    categories,
    form,
    getFocuses,
    existingFocuses,
    disable,
    handleRemove,
  } = props;
  const [focus, setFocus] = useState({});
  const [focuses, setFocuses] = useState([]);
  const {
    subcategoriesData,
    setSubcategoriesData,
    getSubcategories,
    itemsData,
    setItemsData,
    getItems,
  } = useGeneric();

  const handleCategoryChange = (e) => {
    const id = e.target.value;
    setFocus({ ...focus, category: id });
    getSubcategories(id);
  };

  const handleSubCategoryChange = (e) => {
    const id = e.target.value;
    setFocus({ ...focus, sub_category: id });
    getItems(id);
  };

  const handleItemChange = (e) => {
    const id = e.target.value;
    setFocuses([
      ...focuses,
      {
        ...focus,
        item: id,
        item_name: itemsData.find((c) => c.id == id)?.name,
      },
    ]);

    // reset();
  };



  const reset = () => {
    setSubcategoriesData([]);
    setItemsData([]);
    form.setFieldsValue({ category: "", sub_category: "", item: "" });
    setFocus({ ...focus, category: "", sub_category: "", item: "" });
  };

  const remove = (index) => {
    focuses.splice(index, 1);
    setFocuses(focuses.filter((_, i) => i !== index));
  };

  useEffect(() => {
    getFocuses(focuses);
  }, [focuses]);

  useEffect(() => {
    // console.log('TESSSSSSSSSSSTTTTTTTTTTTTTTTTTT ONWE')
    if (existingFocuses) {
      const _focus = existingFocuses;
      const _focuses = [];
      _focus.map((f) => {
        const __focus = {
          category: f?.category?.toString(),
          item: f?.item?.toString(),
          sub_category: f?.sub_category?.toString(),
          item_name: f?.item_name,
        };
        _focuses.push(__focus);
      });
      // focuses(null)
      setFocuses([..._focuses]);
    }
  }, [existingFocuses]);

  return (
    <>
      {!existingFocuses ? (
        <>
          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Category</p>
            <Form.Item onChange={handleCategoryChange} name={"category"}>
              <select
                className="form-field__input__2 form-field__sel bg-white"
                disabled={disable}
              >
                <option value={""}>Select Category</option>
                {categories?.map((category) => (
                  <option key={category?.name} value={category?.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Sub Category</p>
            <Form.Item onChange={handleSubCategoryChange} name={"sub_category"}>
              <select
                className="form-field__input__2 form-field__sel bg-white"
                disabled={disable}
              >
                <option value={""}>Select Sub Category</option>
                {focus?.category ? (
                  <>
                    {subcategoriesData?.map((subcategory) => (
                      <option key={subcategory?.name} value={subcategory?.id}>
                        {subcategory.name}
                      </option>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Item</p>
            <Form.Item onChange={handleItemChange} name={"item"}>
              <select
                className="form-field__input__2 form-field__sel bg-white"
                disabled={disable}
              >
                <option value={""}>Select Item</option>
                {focus?.sub_category ? (
                  <>
                    {itemsData?.map((item) => (
                      <option key={item?.name} value={item?.id}>
                        {item.name}
                      </option>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </select>
            </Form.Item>
          </div>
        </>
      ) : (
        <></>
      )}

      {!existingFocuses && focuses.length ? (
        <MultiSelectedFormItems
          items={focuses}
          prop={"item_name"}
          remove={remove}
          select={() => { }}
          disable={disable}
        />
      ) : (
        <></>
      )}

      {existingFocuses && focuses.length && (
        <MultiSelectedFormItems
          items={existingFocuses}
          prop={"item_name"}
          remove={(index) => handleRemove(index)}
          select={() => { }}
          disable={disable}
        />
      )}
    </>
  );
};

export default Focus;
