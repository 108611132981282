import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../slices/globalSlice";
import axiosInstance from "../../axios/axiosInterceptors";

function useLogin() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [forgotPasswordLoading, setforgotPasswordLoading] = useState(false);
	const [forgotPasswordFailure, setForgotPasswordFailure] = useState(null);

	const forgotUser = async (values) => {
		setForgotPasswordFailure(null);
		setforgotPasswordLoading(true);
		try {
			const { data } = await axiosInstance.post("user/auth/password/forgot", values);
			const serverResponse = data?.data;

			localStorage.setItem("agro-user", JSON.stringify(serverResponse));
			dispatch(setUser(serverResponse));
			navigate("/user/home");
			return data;
		} catch (error) {
			const message = error?.response?.data?.message;
			const isObject = typeof error === "object";
			setForgotPasswordFailure(message);
			setforgotPasswordLoading(false);
		}
	};

	return { forgotPasswordLoading, forgotPasswordFailure, setForgotPasswordFailure, forgotUser };
}

export default useLogin;
