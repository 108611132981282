import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../slices/globalSlice";
import axiosInstance from "../../axios/axiosInterceptors";

function useResetPassword() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
	const [resetPasswordFailure, setResetPasswordFailure] = useState(null);

	const resetUser = async (values) => {
		setResetPasswordFailure(null);
		setResetPasswordLoading(true);
		try {
			const { data } = await axiosInstance.post("user/auth/password/reset", values);
			const serverResponse = data?.data;

			localStorage.setItem("agro-user", JSON.stringify(serverResponse));
			dispatch(setUser(serverResponse));
			navigate("/auth/signin");
			return data;
		} catch (error) {
			const message = error?.response?.data?.message;
			const isObject = typeof error === "object";
			setResetPasswordFailure(message);
			setResetPasswordLoading(false);
		}
	};

	return { resetPasswordLoading, resetPasswordFailure, setResetPasswordFailure, resetUser };
}

export default useResetPassword;
