import React, { useState } from "react";
import { Table, Form, message } from "antd";
import AgroButton from "../../../../misc/AgroButton";
import useGroupServices from "../../../../../utils/hooks/user/useGroupServices";
import InlineError from "../../../../misc/InlineError";
import { cooperativeMembersListColumns } from "../../../../../utils/misc/groupServices";
import { Link } from 'react-router-dom';

const success = "Successful";
const error = "An error has occured";

const ListMembers = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [due, setDue] = useState(null);
  const { cooperative, loading, cooperativeDues } = props;
  const data = cooperative?.members;
  const filterMembers = (member) => {
    return selectedRowKeys.includes(member.id);
  }
  const selectedMembers = data.filter(filterMembers);

  // console.log(selectedMembers)
  let dataSource =
    data &&
    data.length > 0 &&
    data.map((row, index) => {
      return {
        key: row?.id,
        sn: index + 1,
        name:
          row?.farmer_record?.first_name || row?.farmer_record?.last_name
            ? `${row?.farmer_record?.first_name ?? ""} ${row?.farmer_record?.last_name ?? ""
            }`
            : row?.farmer_record?.company_name,
        status: row?.farmer_record?.publish ? "PUBLISHED" : "NOT PUBLISHED",
        lastDuePaid: "-",
        phone: row?.farmer_record?.phone,
        manage: (
          <div className={"flex justify-between"}>

            <Link
              to={{
                pathname: "/user/group-services/cooperatives/manage-members",
                search: `?cooperative_id=${cooperative?.id}&member_id=${row?.farmer_record?.id}&due_id=${due}&member_name=${(row?.farmer_record?.first_name || row?.farmer_record?.last_name) ? row?.farmer_record?.first_name + " " + row?.farmer_record?.last_name : row?.farmer_record?.company_name}`
              }}
            >
              <AgroButton
                primary
                text={"Manage"}
                type="button"
                className={"text-[10px]"}
              // disabled={!due}
              />
            </Link>

          </div>
        ),
      };
    });
  const {
    cooperativeDuesAttachedToFarmersLoading,
    cooperativeDuesAttachedToFarmersFailure,
    setCooperativeDuesAttachedToFarmersFailure,
    getCooperativeDuesAttachedToFarmers,
    attachDueToFarmerLoading,
    setAttachDueToFarmerLoading,
    attachDueToFarmerFailure,
    setAttachDueToFarmerFailure,
    attachDueToFarmer
  } = useGroupServices();

  const addDue = () => {
    const filterCooperativeDues = (currentDue) => {
      return currentDue.id == due;
    }

    const selectedDue = cooperativeDues.data.filter(filterCooperativeDues);
    console.log(selectedDue);

    selectedMembers.forEach(function (item, index) {
      const data = {
        farmer_record_id: item.farmer_record?.id,
        amount_due: selectedDue[0].amount,
        amount_paid: 0,
        amount_balance: selectedDue[0].amount
      }
      attachDueToFarmer(cooperative.id, due, data).then((data) => {
        if (data?.success) {
          message.success(data?.message || success);

          // completed();
        } else {
          message.error(data?.message || error);
        }
      });
    })

  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // console.log(newSelectedRowKeys)
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const handleDueChange = (e) => {
    const id = e.target.value;
    setDue(id);
    // console.log(id);
  };

  return (
    <>
      <InlineError
        control={cooperativeDuesAttachedToFarmersFailure}
        message={cooperativeDuesAttachedToFarmersFailure}
        onClose={() => setCooperativeDuesAttachedToFarmersFailure(null)}
      />
      <div className={"mt-2"}>
        <div className={"flex items-center gap-x-2 mb-2"}>
          <Form
            autoComplete="on"
            className="flex items-center justify-start md:justify-between flex-wrap w-[20%]"
          >
            <div className="w-full lg:my-0 ">
              <Form.Item
                name={"type"}
                rules={[{ required: true, message: "Required" }]}
                onChange={handleDueChange}
                className={"due-form"}
              >
                <select className="form-field__input__2 form-field__sel bg-white dues">
                  <option value={""}>Select Due</option>
                  cooperativeDues ?
                  {cooperativeDues?.data?.map((d) => (
                    <option key={d.name} value={d.id}>
                      {d.name}
                    </option>
                  ))}{" "}
                  : <></>
                </select>
              </Form.Item>
            </div>
          </Form>
          <AgroButton
            loading={cooperativeDuesAttachedToFarmersLoading}
            primary
            onClick={addDue}
            disabled={
              cooperativeDuesAttachedToFarmersLoading || (!hasSelected || !due)
            }
            text={
              cooperativeDuesAttachedToFarmersLoading
                ? "Please wait..."
                : "Add Due"
            }
            className={"text-[10px]"}
          />
          <span>
            {hasSelected ? `Selected ${selectedRowKeys.length} farmers` : ""}
          </span>
        </div>
        <Table
          className={"farmerList text-[14px]"}
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table-row-light text-[13px]"
              : "table-row-dark text-[13px]"
          }
          bordered={true}
          dataSource={dataSource}
          columns={cooperativeMembersListColumns}
          loading={loading}
          rowSelection={rowSelection}
        />
      </div>
    </>
  );
};

export default ListMembers;
