import { BiSupport } from "react-icons/bi";
import { FaDatabase, FaRegChartBar } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";

import {
  Fi24Hours48Svg,
  FiFreeDelivery48Svg,
  FiPaymentSecurity48Svg,
  FiTag48Svg,
} from '../../components/svg';

const serviceOffers = [
  {
    Icon: FaRegChartBar,
    title: "Trading",
    subtitle: "Subtitle",
    iconPath: "/icons/services/trading.png",
  },
  {
    Icon: FaDatabase,
    title: "Data Management",
    subtitle: "Subtitle",
    iconPath: "/icons/services/farmsdata.png",
  },
  {
    Icon: BiSupport,
    title: "Technical Support",
    subtitle: "Subtitle",

    iconPath: "/icons/services/support.png",
  },
  {
    Icon: GiReceiveMoney,
    title: "Loans & Grants",
    subtitle: "Subtitle",
    iconPath: "/icons/services/loans.png",
  },

  
  // {
  // 	Icon: < />, title: "Aggregation & Distribution",
  // subtitle: 'Subtitle',

  // 	iconPath: "/icons/services/distribution.png",
  // },
  // {
  // 	Icon: < />, title: "Cooperatives & Associations",
  // subtitle: 'Subtitle',

  // 	iconPath: "/icons/services/coop.png",
  // },
  // {
  // 	Icon: < />, title: "Record Management Services",
  // subtitle: 'Subtitle',

  // 	iconPath: "/icons/services/recordmgt.png",
  // },
];

const serviceOfferings = [
  {
    Icon: FiTag48Svg,
    title: "Hot Offers",
    subtitle: "Discount up to 90%",
    iconPath: "/icons/services/trading.png",
  },
  {
    Icon: FiPaymentSecurity48Svg,
    title: "Safety 100%",
    subtitle: "Only secure payments",
    iconPath: "/icons/services/farmsdata.png",
  },
  {
    Icon: Fi24Hours48Svg,
    title: "Support 24/7",
    subtitle: "Call us anytime",

    iconPath: "/icons/services/support.png",
  },
  {
    Icon: FiFreeDelivery48Svg,
    title: "Nationwide Coverage",
    subtitle: "For all transactions",
    iconPath: "/icons/services/loans.png",
  },
]

const dummyProducts = [
  {
    id: 8,
    imgPath: "/images/products/rice.jpg",
    prName: "Basmati Rice",
    prevPrice: 1500,
    currentPrice: 1000,
    rating: 3,
    category: "rice",
    reviews: 10,
  },
  {
    id: 8,
    imgPath: "/images/products/carrots.jpg",
    prName: "Fresh Carrots",
    prevPrice: 1500,
    currentPrice: 1000,
    rating: 5,
    category: "carrots",
    reviews: 2,
  },
  {
    id: 8,
    imgPath: "/images/products/beans.jpg",
    prName: "Neatly Picked Beans",
    prevPrice: 1500,
    currentPrice: 1000,
    rating: 5,
    category: "beans",
    reviews: 20,
  },
  {
    id: 8,
    imgPath: "/images/products/corn.jpg",
    prName: "Fresh Corn",
    prevPrice: 1500,
    currentPrice: 1000,
    rating: 3,
    category: "cereals",
    reviews: 2,
  },
  {
    id: 9,
    imgPath: "/images/products/corn.jpg",
    prName: "Imported Corn",
    prevPrice: 1500,
    currentPrice: 1000,
    rating: 3,
    category: "cereals",
    reviews: 2,
  },
];

const clientReviews = [
  {
    clientName: "Laura Wilson",
    datePosted: "2022-01-04T07:58:36.000000Z",
    imgSrc:
      "https://res.cloudinary.com/ayotheinspired/image/upload/v1643571226/agroclerk/reviewImgs/jasonadf_eyo6gq.jpg",
    review:
      "AgroClerk is a game changer for the Agricultural Sector, removing the various bottlenecks and challenges that have plagued the sector for too long.",
    stars: 5,
  },
  {
    clientName: "Adrian Lewis",
    datePosted: "2022-01-04T07:58:36.000000Z",
    imgSrc:
      "https://res.cloudinary.com/ayotheinspired/image/upload/v1643571226/agroclerk/reviewImgs/jasonadf_eyo6gq.jpg",
    review:
      "AgroClerk provides the base technology infrastructure that the agricultural sector can leverages upon to unearth the potentials locked in Agriculture ",
    stars: 4,
  },
  {
    clientName: "Alice Grant",
    datePosted: "2022-01-04T07:58:36.000000Z",
    imgSrc:
      "https://res.cloudinary.com/ayotheinspired/image/upload/v1643571226/agroclerk/reviewImgs/jasonadf_eyo6gq.jpg",
    review:
      "AgroClerk is cocktail of solutions that aims to fix the broken value chain by leveraging on rapid information exchange using software technology",
    stars: 5,
  },
];

const mediaItems = [
  {
    title: "Blogs",
    children: [
      "Politics",
      "Crime",
      "Romance",
      "Jobs/Vacancies",
      "Career",
      "Business",
      "Investment",
      "NYSC",
      "Education",
      "Autos",
      "Car Talk",
      "Properties",
      "Health",
      "Travel",
      "Family",
    ],
  },
  {
    title: "Forums",
    children: [
      "Culture",
      "TV",
      "Movies",
      "Music",
      "Radio",
      "Sports",
      "Fashion",
      "Celebrities",
      "Events",
      "Gaming",
      "Literature",
      "Business",
      "Finance",
      "Leadership",
      "Career",
    ],
  },
  {
    title: "Directories",
    children: [
      "Culture",
      "TV",
      "Movies",
      "Music",
      "Radio",
      "Sports",
      "Fashion",
      "Celebrities",
      "Events",
      "Gaming",
      "Literature",
      "Business",
      "Finance",
      "Leadership",
      "Career",
    ],
  },
  {
    title: "Events",
    children: [
      "Events",
      "Events",
      "Events",
      "Events",
      "Events",
      "Events",
      "Events",
      "Events",
      "Events",
      "Events",
      "Events",
      "Events",
      "Events",
      "Events",
      "Events",
    ],
  },
];

const homeTopBlogs = [
  {
    itemLeft: {
      label: "Farmer Data Management",
      css: "item-left",
      imgPath: "/images/farmer_data.png",
      delay: "0",
    },
    itemRight: {
      label: "Cooperatives & Associations Management",
      css: "item-right",
      imgPath: "/images/associations.png",
      delay: "300",
    },
  },
  {
    itemLeft: {
      label: "Market Place (Escrow Payment Services)",
      css: "item-left",
      imgPath: "/images/farm_market.png",
      delay: "600",
    },
    itemRight: {
      label: "Loans, Insurance & Finances",
      css: "item-right",
      imgPath: "/images/finance.png",
      delay: "900",
    },
  },
  {
    itemLeft: {
      label: "Farm Record Management",
      css: "item-left",
      imgPath: "/images/data.png",
      delay: "1200",
    },
    itemRight: {
      label: "Media Services",
      css: "item-right",
      imgPath: "/images/media_services.png",
      delay: "1500",
    },
  },
  {
    itemLeft: {
      label: "Field Services",
      css: "item-left",
      imgPath: "/images/field_services.png",
      delay: "1200",
    },
    itemRight: {
      label: "Technical Services",
      css: "item-right",
      imgPath: "/images/technical_2.png",
      delay: "1500",
    },
  },
];

export {
  serviceOffers,
  serviceOfferings,
  homeTopBlogs,
  clientReviews,
  dummyProducts,
  mediaItems,
};
