import { Form, Input } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import _ from "lodash";
import useLogin from "../../utils/hooks/auth/useLogin";
import { AgroButton, InlineError } from "../../components";
import AuthLayout from "../../containers/layouts/AuthLayout";
import useResetPassword from "../../utils/hooks/auth/useResetPassword";

function ResetPassword() {
	const { resetPasswordLoading, resetPasswordFailure, setResetPasswordFailure, resetUser } = useResetPassword();
	const [params] = useSearchParams()
	const uppercaseRegex = /(?=.*[A-Z])/;
	const onSubmit = (values) => {
		const formData = new FormData();
		_.forEach(values, (value, key) => {
			formData.append(key, value);
		});
		formData.append('token',params?.get('token'));
		formData.append('email',params?.get('email'));
		resetUser(formData);
	};

	return (
		<AuthLayout>
			<p className="mb-4 font-semibold text-lg lg:text-xl text-center">
			Complete your details to reset your password
			</p>
			<InlineError
				control={resetPasswordFailure}
				message={resetPasswordFailure}
				onClose={() => setResetPasswordFailure(null)}
			/>
			<Form onFinish={onSubmit} autoComplete="on" className="mt-5">
			

				<div className="mb-2">
					<p className="form-label">New Password</p>
					<Form.Item
						name="password"
						rules={[
						{ required: true, message: "Required" },
						{ min: 8, message: "Must be at least 8 characters" },
						{
							validator: (_, value) =>
							!value || (value && value.match(uppercaseRegex))
								? Promise.resolve()
								: Promise.reject("Must include one uppercase letter"),
						},
						]}
					>
                <Input.Password
                  className="form-field__input"
                  placeholder="Enter your password"
                />
              </Form.Item>
				</div>
				<div className="mb-2">
					<p className="form-label">Confirm Password</p>
					<Form.Item
                name="password_confirmation"
                rules={[
                  { required: true, message: "Required" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Passwords do not match");
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="form-field__input"
                  placeholder="Confirm password"
                />
              </Form.Item>
				</div>
				<div className="flex justify-center my-3">
					<AgroButton
						disabled={resetPasswordLoading}
						loading={resetPasswordLoading}
						primary
						text={resetPasswordLoading ? "Submitting" : "Reset"}
						type="submit"
					/>
				</div>
			</Form>

			<div className="flex items-center justify-between">
				<div className="">
					<p className="text-sm">
						<Link
							className="text-orange-400 hover:underline hover:text-orange-400 ml-1.5  font-semibold tracking-wide"
							to="/auth/signup">
							Create Account
						</Link>
					</p>
				</div>				
			</div>
		</AuthLayout>
	);
}

export default ResetPassword;
