import React, { useEffect, useState } from "react";
import useCountriesStates from "../../../../utils/hooks/auth/useCountriesStates";
import { Form, message, Input } from "antd";
import MultiSelectedFormItems from "./MultiSelectedItems";
import LocationList from "./ViewAndEditFarmerData/LocationList";
import useFarmerData from "../../../../utils/hooks/user/useFarmerData";
import { AgroButton } from "../../..";
const success = "Successful";
const error = "An error has occured";

const valuesInit = {
  country: "",
  state: "",
  city: "",
  address: "",
  country_name: "",
  state_name: "",
  city_name: "",
};

const Location = (props) => {
  const {
    countries,
    form,
    getLocations,
    disable,
    existingLocations,
    farmRecordId,
    handleRemove,
  } = props;
  const [location, setLocation] = useState(valuesInit);
  const [locations, setLocations] = useState([]);
  const [visibility, setVisibility] = useState(true);
  const {
    statesData,
    setStatesData,
    getStates,
    citiesData,
    setCitiesData,
    getCities,
  } = useCountriesStates();
  const { deleteLocationLoading, deleteLocation } = useFarmerData();

  const addLocation = () => {
    form
      .validateFields([
        "country",
        "state",
        "city",
        "address",
        "contact_email",
        "contact_phone",
      ])
      .then((vals) => {
        const data = {
          ...vals,
          country_name: "",
          state_name: "",
          city_name: citiesData.find((c) => c.id == vals.city)?.name,
        };
        setLocations([...locations, { ...data }]);
        reset();
      })
      .catch((error) => { });
  };

  const handleCountryChange = (e) => {
    const id = e.target.value;
    setLocation({ ...location, country: id });
    getStates(id);
  };

  const handleStateChange = (e) => {
    const id = e.target.value;
    setLocation({ ...location, state: id });
    getCities(id);
  };

  const handleCityChange = (e) => {
    const id = e.target.value;
    setLocation({
      ...location,
      city: id,
    });
    // reset();
  };

  const reset = () => {
    setStatesData([]);
    setCitiesData([]);
    form.setFieldsValue({ country: "", state: "", city: "", address: "" });
    setLocation({ ...location, ...valuesInit });
    setVisibility(false);
  };

  const remove = (index) => {
    locations.splice(index, 1);
    setLocations(locations.filter((_, i) => i !== index));
  };

  const handleDelete = (index) => {
    console.log(farmRecordId, locations[index]);
    // locations.splice(index, 1);
    // setLocations(locations.filter((_, i) => i !== index));
    deleteLocation(farmRecordId, locations[index]?.id).then((data) => {
      if (data?.success) {
        message.success(data?.message || success);
        handleRemove(index);
      } else {
        message.error(data?.message || error);
      }
    });
  };

  useEffect(() => {
    getLocations(locations);
  }, [locations]);

  useEffect(() => {
    console.log(existingLocations);
    if (existingLocations) {
      const _locations = existingLocations;
      const __locations = [];
      _locations.map((l) => {
        const __ll = {
          id: l?.id,
          country: l?.country?.toString(),
          state: l?.state?.toString(),
          city: l?.city?.toString(),
          country_name: l?.country_name,
          state_name: l?.state_name,
          city_name: l?.city_name,
          address: l?.address,
        };
        __locations.push(__ll);
      });
      setLocations([...__locations]);
    }
  }, [existingLocations]);

  return (
    <>
      {!existingLocations ? (
        <>
          {visibility ? (
            <>
              <div className="w-full md:w-[29%] lg:my-0">
                <p className="form-label text-xs">Country</p>
                <Form.Item
                  onChange={handleCountryChange}
                  name={"country"}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <select className="form-field__input__2 bg-white form-field__sel">
                    <option value={""}>Select Country</option>
                    {countries?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item?.name?.length > 20
                          ? `${item.name.substring(0, 30)}...`
                          : item.name}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </div>

              <div className="w-full md:w-[29%] lg:my-0">
                <p className="form-label text-xs">State</p>
                <Form.Item
                  onChange={handleStateChange}
                  name={"state"}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <select className="form-field__input__2 bg-white form-field__sel">
                    <option value={""}>Select State</option>
                    {statesData?.map((state) => (
                      <option key={state?.name} value={state?.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </div>

              <div className="w-full md:w-[29%] lg:my-0">
                <p className="form-label text-xs">City</p>
                <Form.Item
                  onChange={handleCityChange}
                  name={"city"}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <select className="form-field__input__2 form-field__sel bg-white">
                    <option value={""}>Select City</option>
                    {citiesData?.map((city) => (
                      <option key={city?.name} value={city?.id}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </div>

              <div className="w-full lg:my-0">
                <p className="form-label text-xs">Address</p>
                <Form.Item
                  name="address"
                  rules={[{ required: true, message: "Required" }]}
                >
                  <Input
                    className="form-field__input__2"
                    placeholder="Address"
                  />
                </Form.Item>
              </div>
              <div className="w-full my-4 flex justify-end gap-x-6">
                {locations.length ? (
                  <AgroButton
                    className={"bg-red-500 text-[#ffffff] hover:bg-red-800"}
                    text={"Close"}
                    type="button"
                    onClick={() => setVisibility(false)}
                  />
                ) : (
                  <></>
                )}
                {!disable ? (
                  <AgroButton
                    primary
                    text={"Save Location"}
                    type="button"
                    onClick={addLocation}
                  />
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <div className="w-full mb-4">
              <AgroButton
                primary
                text={"Add Location"}
                type="button"
                onClick={() => setVisibility(true)}
                className={"text-[10px]"}
              />
            </div>
          )}
          {locations.length ? (
            <MultiSelectedFormItems
              items={locations}
              prop={"city_name"}
              remove={remove}
              select={() => { }}
              disable={disable}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {existingLocations && locations.length ? (
        <LocationList
          items={locations}
          handleDelete={handleDelete}
          select={() => { }}
          disable={disable}
          deleteLocationLoading={deleteLocationLoading}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Location;
