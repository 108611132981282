import React, { useEffect, useState } from "react";
import useCountriesStates from "../../../../../utils/hooks/auth/useCountriesStates";
const styles = "px-1 flex gap-x-1 items-center cursor-pointer";

const ViewCooperative = ({ cooperative }) => {
  
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const {
    countriesData,
    getCountries,
    statesData,
    getStates,
    citiesData,
    getCities,
  } = useCountriesStates();

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getStates(cooperative?.country_id);
  }, []);

  useEffect(() => {
    getCities(cooperative?.state_id);
  }, []);

  useEffect(() => {
    const country_ = countriesData.find(
      (c) => c.id === cooperative?.country_id
    )?.name;
    setCountry(country_);
  }, [countriesData]);

  useEffect(() => {
    const state_ = statesData.find((s) => s.id === cooperative?.state_id)?.name;
    setState(state_);
  }, [statesData]);

  useEffect(() => {
    const city_ = citiesData.find((c) => c.id === cooperative?.city_id)?.name;
    setCity(city_);
  }, [citiesData]);

  return (
    <>
   
      <div className="mt-5">
        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full md:w-[47%] lg:my-0 ">
            <p className="form-label text-xs">Name</p>
            <div className={"border p-2 flex justify-between"}>
              <div className={styles}>{cooperative?.name}</div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
          <div className="w-full mb-2 mt-6 flex justify-between items-center">
            <p className="form-label">Focus</p>
          </div>
          {cooperative?.cooperative_focus_items.map((item, index)=>(
            <div key={index} className="flex items-center justify-start md:justify-between flex-wrap w-full">
              <div className="w-full md:w-[30%] lg:my-0">
                <p className="form-label text-xs">Category</p>
                <div className={"border p-2 flex justify-between"}>
                  <div className={styles}>{item?.item.item_category?.name}</div>
                </div>
              </div>

              <div className="w-full md:w-[30%] lg:my-0">
                <p className="form-label text-xs">Sub Category</p>
                <div className={"border p-2 flex justify-between"}>
                  <div className={styles}>{item?.item?.item_sub_category?.name}</div>
                </div>
              </div>

              <div className="w-full md:w-[30%] lg:my-0">
                <p className="form-label text-xs">Item</p>
                <div className={"border p-2 flex justify-between"}>
                  <div className={styles}>{item?.item.name}</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
          <div className="w-full mb-2 mt-6 flex justify-between items-center">
            <p className="form-label">Location</p>
          </div>
          <div className="w-full md:w-[30%] lg:my-0">
            <p className="form-label text-xs">Country</p>
            <div className={"border p-2 flex justify-between"}>
              <div className={styles}>{country}</div>
            </div>
          </div>

          <div className="w-full md:w-[30%] lg:my-0">
            <p className="form-label text-xs">State</p>
            <div className={"border p-2 flex justify-between"}>
              <div className={styles}>{state}</div>
            </div>
          </div>

          <div className="w-full md:w-[30%] lg:my-0">
            <p className="form-label text-xs">City</p>
            <div className={"border p-2 flex justify-between"}>
              <div className={styles}>{city}</div>
            </div>
          </div>

          <div className="w-full mt-6">
            <p className="form-label text-xs">Address</p>
            <div className={"border p-2 flex mt-2 justify-between"}>
              <div className={styles}>{cooperative?.address}</div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full lg:my-0">
            <p className="form-label mt-6">Website</p>
            <div className={"border mt-2 p-2 flex justify-between"}>
              <div className={styles}>{cooperative?.website}</div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full md:w-[47%] lg:my-0">
            <p className="form-label mt-4">Phone Number</p>
            <div className={"border mt-2 p-2 flex justify-between"}>
              <div className={styles}>{cooperative?.phone}</div>
            </div>
          </div>

          <div className="w-full md:w-[47%] lg:my-0">
            <p className="form-label">Email Address</p>
            <div className={"border p-2 flex justify-between"}>
              <div className={styles}>{cooperative?.email}</div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="mb-2 mt-4 ">
            <p className="form-label">Contact Persons</p>
          </div>
          
          <div className={"border p-2"}>
            <div
              className={
                "border border-gray-400 rounded bg-gray-200 px-1"
              }
            >
              {cooperative.contacts.map((item, index)=>{
                return (
                  <div className="row mt-4" key={item.id}>
                                      
                    <div className="col-md-4 mt-3 mt-md-0">
                      <div className="form-group">
                        <label className="text-xs">Title</label>
                        <select name="title" className="form-control" readOnly>
                          <option value={item?.title}>{item?.title}</option>
                         
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4 mt-3 mt-md-0">
                      <div className="form-group">
                        <label className="text-xs">First Name</label>
                        <input readOnly name="first_name" type="text" className="form-control" placeholder="First Name of contact person" defaultValue={item?.first_name} />
                      </div>
                    </div>

                    <div className="col-md-4 mt-3 mt-md-0">
                      <div className="form-group">
                        <label className="text-xs">Surname</label>
                        <input readOnly name="surname" type="text" className="form-control" placeholder="Last Name of contact person" defaultValue={item?.surname}/>
                        
                      </div>
                    </div>


                    <div className="col-md-4 mt-3 mt-md-0">
                      <div className="form-group">
                        <label className="text-xs">Role</label>
                        <select name="role" className="form-control" defaultValue={"Chairman"}>
                          <option value={item?.role}>{item?.role}</option>
                          
                          
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4 mt-3 mt-md-0">
                      <div className="form-group">
                        <label className="text-xs">Email</label>
                        <input readOnly name="contact_email" type="email" className="form-control" placeholder="Email of contact person" defaultValue={item?.email} />
                      </div>
                    </div>

                    <div className="col-md-4 mt-3 mt-md-0">
                      <div className="form-group">
                        <label className="text-xs">Phone</label>
                        <input readOnly name="contact_phone" type="phone" className="form-control webkit-readOnly" placeholder="Phone number of contact person" defaultValue={item?.phone} />
                      </div>
                    </div>
                    <div>
                      <hr className="mt-3" /> 
                    </div>
                    
                  </div>
                   
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCooperative;
