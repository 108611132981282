import React from "react";
import { useSelector } from "react-redux";
import { UserFullName, UserSidebarHome } from "../..";
import { NavLink, useLocation } from "react-router-dom";

import { userNavItems } from "../../../utils/misc/userHomeData";
import { userHome } from "../../../utils/misc/navData";
import SignoutModal from "../../SignoutModal";
import { FaSignOutAlt } from "react-icons/fa";
import { useState } from "react";

function HomeLeftSidebar() {
  const { user } = useSelector((state) => state.global);
  const [signOut, setSignOut] = useState(false);

  const { pathname } = useLocation();
  return (
    <div>
      <UserFullName />

      <div className="flex flex-col">
        <UserSidebarHome isActive={pathname === userHome ? true : false} />
        {userNavItems.slice(1).map(({ title, Icon, path }) => (
          <NavLink
            key={title}
            to={path}
            className={({ isActive }) =>
              isActive ? "style_lg_active" : "none"
            }
          >
            <div className="group left-sidebar-item">
              <span className="mr-2 text-black">
                <Icon
                  size="1.3rem"
                  className="group-hover:grn-text transition-all left-sidebar-icon"
                />
              </span>
              <p className="left-sidebar-text">{title}</p>
            </div>
          </NavLink>
        ))}
        <div className="block lg:hidden">
          <span
            className={({ isActive }) =>
              isActive ? "style_lg_active" : "none"
            }
          >
            <button className="group left-sidebar-item cursor-default" onClick={() => setSignOut(true)} >
              <span className="mr-2 text-black">
                <FaSignOutAlt
                  size="1.3rem"
                  className="group-hover:grn-text transition-all left-sidebar-icon"
                />
              </span>
              <p className="left-sidebar-text">Sign out</p>
            </button>
          </span>
        </div>
      </div>

      <SignoutModal open={signOut} onClose={() => setSignOut(false)} />
    </div>
  );
}

export default HomeLeftSidebar;
