import { useState } from "react";
import axiosInstance from "../../axios/axiosInterceptors";

const useGeneric = () => {
  
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [categoriesError, setCategoriesError] = useState(null);
  const [categoriesData, setCategoriesData] = useState([]);

  const [subcategoriesLoading, setSubcategoriesLoading] = useState(false);
  const [subcategoriesError, setSubcategoriesError] = useState(null);
  const [subcategoriesData, setSubcategoriesData] = useState([]);

  const [subcategoriesListLoading, setSubcategoriesListLoading] = useState(false);
  const [subcategoriesListError, setSubcategoriesListError] = useState(null);
  const [subcategoriesListData, setSubcategoriesListData] = useState([]);

  const [subcategoriesForACategoryLoading, setSubcategoriesForACategoryLoading] = useState(false);
  const [subcategoriesForACategoryError, setSubcategoriesForACategoryError] = useState(null);
  // const [subcategoriesForACategoryData, setSubcategoriesForACategoryData] = useState([]);

  const [itemsLoading, setItemsLoading] = useState(false);
  const [itemsError, setItemsError] = useState(null);
  const [itemsData, setItemsData] = useState([]);

  const [itemsListLoading, setItemsListLoading] = useState(false);
  const [itemsListError, setItemsListError] = useState(null);
  const [itemsListData, setItemsListData] = useState([])

  const [blogCategoriesLoading, setBlogCategoriesLoading] = useState(false);
  const [blogCategoriesError, setBlogCategoriesError] = useState(null);
  const [blogCategoriesData, setBlogCategoriesData] = useState([]);

  const getCategories = async () => {
    setCategoriesLoading(true);
    setCategoriesError(null);
    try {
      const { data } = await axiosInstance.get("generic/items/categories");
      const serverResponse = data?.data?.categories;
      setCategoriesData(serverResponse);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setCategoriesError(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCategoriesLoading(false);
    }
  };

  const getSubcategories = async (id) => {
    setSubcategoriesLoading(true);
    setSubcategoriesError(null);
    try {
      const { data } = await axiosInstance.get(
        `generic/items/categories/${id}/subcategories`
      );
      const serverResponse = data?.data?.subcategories;
      setSubcategoriesData(serverResponse);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setSubcategoriesError(
        isObject ? Object.values(message).join(" | ") : message
      );
      setSubcategoriesLoading(false);
    }
  };

  const getSubcategoriesList = async (id, index) => {
    setSubcategoriesListLoading(true);
    setSubcategoriesListError(null);
    try {
      const { data } = await axiosInstance.get(
        `generic/items/categories/${id}/subcategories`
      );

      const serverResponse = await data?.data?.subcategories;
      const subCats = subcategoriesListData;
      subCats[index] = serverResponse;
      setSubcategoriesListData(subCats);
      return subCats;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setSubcategoriesListError(
        isObject ? Object.values(message).join(" | ") : message
      );
      setSubcategoriesListLoading(false);
    }
  };

  const getSubcategoriesForACategory = async (id, index) => {
    setSubcategoriesForACategoryLoading(true);
    setSubcategoriesForACategoryError(null);
    try {
      const { data } = await axiosInstance.get(
        `generic/items/categories/${id}/subcategories`
      );

      const serverResponse = await data?.data?.subcategories;
      const subCats = [...subcategoriesListData];
      
      subCats[index] = await serverResponse;
      setSubcategoriesListData(subCats);
        console.log(subCats)
      return subCats;
      
    } catch (error) {
      const message =
      error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setSubcategoriesForACategoryError(
        isObject ? Object.values(message).join(" | ") : message
      );
      setSubcategoriesForACategoryLoading(false);
    }
  };

  const getItems = async (id) => {
    setItemsLoading(true);
    setItemsError(null);
    try {
      const { data } = await axiosInstance.get(
        `generic/items/subcategories/${id}`
      );
      const serverResponse = data?.data?.items;
      setItemsData(serverResponse);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setItemsError(isObject ? Object.values(message).join(" | ") : message);
      setItemsLoading(false);
    }
  };

  const getItemsList = async (id, index, instance = false) => {
    setItemsListLoading(true);
    setItemsListError(null);
    try {
      const { data } = await axiosInstance.get(
        `generic/items/subcategories/${id}`
      );
      const serverResponse = await data?.data?.items;
      const items = instance ? [...itemsListData]: itemsListData;
      
      items[index] = await serverResponse;
      setItemsListData(items);
      return items;

    } catch (error) {
      const message =
      error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setItemsListError(isObject ? Object.values(message).join(" | ") : message);
      setItemsListLoading(false);
    }
  };

  const getBlogCategories = async () => {
    setBlogCategoriesLoading(true);
    setBlogCategoriesError(null);
    try {
      const { data } = await axiosInstance.get("generic/categories");
      const serverResponse = data?.data?.categories.data;
      setBlogCategoriesData(serverResponse);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setBlogCategoriesError(
          isObject ? Object.values(message).join(" | ") : message
      );
      setBlogCategoriesLoading(false);
    }
  };


  return {
    categoriesLoading,
    categoriesError,
    categoriesData,
    getCategories,
    blogCategoriesLoading,
    blogCategoriesError,
    blogCategoriesData,
    getBlogCategories,
    subcategoriesLoading,
    subcategoriesError,
    subcategoriesData,
    setSubcategoriesData,
    getSubcategories,
    itemsLoading,
    itemsError,
    itemsData,
    setItemsData,
    getItems,
    subcategoriesListLoading,
    subcategoriesListError,
    subcategoriesListData,
    setSubcategoriesListData,
    getSubcategoriesList,
    subcategoriesForACategoryLoading,
    subcategoriesForACategoryError,
    getSubcategoriesForACategory,
    itemsListLoading,
    itemsListError,
    itemsListData,
    setItemsListData,
    getItemsList,
  };
};

export default useGeneric;
