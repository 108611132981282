import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CgMenuGridR } from "react-icons/cg";
import { MdNotificationsActive } from "react-icons/md";
import { TiArrowSortedDown } from "react-icons/ti";
import { FaExclamationTriangle, FaSignOutAlt } from 'react-icons/fa'
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../../slices/globalSlice";
import { Modal } from "antd";
import SignoutModal from "../../../components/SignoutModal";

function NavRight() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAlertOpen, setIsAlertsOpen] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const [signOut, setSignOut] = useState(false);

  const rightNavItems = [
    {
      title: "Menu",
      control: isMenuOpen,
      Icon: CgMenuGridR,
      toggle: () => handleItemClicked('menu'),
    },
    {
      title: "Notifications",
      control: isAlertOpen,
      Icon: MdNotificationsActive,
      toggle: () => handleItemClicked('alert'),
    },
    {
      title: "Account",
      control: isAccountOpen,
      Icon: TiArrowSortedDown,
      toggle: () => handleItemClicked('account'),
    },
  ];

  const { user } = useSelector((state) => state.global);
  const lastName = user?.user?.name.split(" ")[1];
  const name = lastName ? lastName : user?.user?.name.split(" ")[0];
  const handleItemClicked = (item) => {
    if (item === 'menu') {
      setIsMenuOpen(!isMenuOpen)
      setIsAlertsOpen(false)
      setIsAccountOpen(false)
    } else if (item === 'alert') {
      setIsMenuOpen(false)
      setIsAlertsOpen(!isAlertOpen)
      setIsAccountOpen(false)
    } else {
      setIsMenuOpen(false)
      setIsAlertsOpen(false)
      setIsAccountOpen(!isAccountOpen)
    }
  }
  return (
    <div className="hidden lg:flex items-center justify-center">
      {/*     <div className="flex items-center py-[2px] pr-2 cursor-pointer rounded-lg hover:bg-gray-200 transition-all mr-3">
        <div className="h-7 w-7 mr-2 rounded-full bg-gray-700 flexed text-white">
          {name[0]}
        </div>
        <p className="mb-0 font-semibold">{name}</p>
      </div> */}
      <div className="flexed">
        {rightNavItems.map(({ title, control, Icon, toggle }) => (
          <div
            onClick={toggle}
            key={title}
            className="mx-2 group relative w-10 lg:w-14 cursor-pointer flexed flex-col"
          >
            <div className="rounded-full h-10 w-10 bg-gray-200 group-hover:bg-gray-300 transition-all p-1 flexed">
              <Icon className={`${control && "grn-text"} `} size="1.5rem" />
            </div>
            <div className="bg-black bg-opacity-90 rounded-md shadow-md hidden group-hover:block py-2 px-3 absolute top-[55px] -left-2">
              <p className="text-center text-white font-semibold whitespace-nowrap">
                {title}
              </p>
            </div>
          </div>
        ))}
      </div>

      {isMenuOpen && (
        <div className="absolute top-16 right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

          <button className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full flex gap-2 items-center">FOR MENU</button>
        </div>
      )}

      {isAlertOpen && (
        <div className="absolute top-16 right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

          <button className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full flex gap-2 items-center">FOR NOTIFICATIONS</button>
        </div>
      )}

      {isAccountOpen && (
        <div className="absolute top-16 right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

          <button className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full flex gap-2 items-center" onClick={() => setSignOut(true)}><FaSignOutAlt />Signout</button>
        </div>
      )}
      <SignoutModal open={signOut} onClose={() => setSignOut(false)} />
    </div>
  );
}

export default NavRight;
