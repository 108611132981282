import { Modal } from 'antd'
import React from 'react'
import { FaExclamationTriangle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../slices/globalSlice';

const SignoutModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.global);

  const handleUserAuth = () => {
    if (!user) {
      navigate("/auth/signin");
    } else {
      localStorage.removeItem("agro-user");
      dispatch(logoutUser());
      navigate("/auth/signin");
    }
  };
  return (
    <Modal
      centered
      open={open}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
    >
      <div>
        <div className="sm:flex sm:items-center justify-start gap-2">
          <div className="flex justify-center">
            <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <FaExclamationTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
          </div>
          <div className="sm:ml-4 sm:mt-0">
            <h3 className="text-center sm:text-left text-base font-semibold text-gray-900">
              Are you sure you want to Sign out?
            </h3>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto sm:mt-0 "
            onClick={() => {
              handleUserAuth()
              onClose()
            }}
          >
            Yes, Sign Out
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SignoutModal
