import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { userNavItems } from "../../../utils/misc/userHomeData";
import NavRight from "./NavRight";

function UserNav() {
  const currentPath = window.document.location.pathname;

  return (
    <Nav className="flex items-center justify-between bg-white shadow-md h-16 p-2 w-full sticky top-0 left-0 z-50">
      <div className="hidden md:inline mr-5 lg:mr-0">
        <NavLink to="/">
          <img
            src="/logo.svg"
            alt="logo"
            className="object-cover w-14 cursor-pointer"
          />
        </NavLink>
      </div>
      <div className="flex items-center justify-between lg:justify-around lg:w-3/5  lg:ml-auto lg:mr-4">
        {userNavItems.map(({ title, Icon, path }) => (
          <div
            key={title}
            className="mx-2 lg:mx-7 group relative w-7 md:w-10 lg:w-14 flexed flex-col"
          >
            {currentPath === path && (
              <p className="text-xs whitespace-nowrap mb-0 lg:hidden">
                {title}
              </p>
            )}
            <NavLink
              style={({ isActive }) => ({
                color: isActive || currentPath === path ? "#3dd800" : "#000",
              })}
              to={path}
            >
              <div className="rounded-full h-10 w-10 group-hover:bg-gray-200 transition-all p-1 flexed">
                <Icon className="" size="1.5rem" />
              </div>
            </NavLink>
            {currentPath === path && <div className="h-[3px] w-full nav-bg" />}

            <div className="bg-black bg-opacity-90 rounded-md shadow-md -translate-x-1/4 hidden group-hover:block py-2 px-3 absolute top-[55px] left-0">
              <p className="text-center text-white font-semibold whitespace-nowrap">
                {title}
              </p>
            </div>
          </div>
        ))}
      </div>
      <NavRight />
    </Nav>
  );
}

export default UserNav;

const Nav = styled.nav``;
